import React from 'react';
import { MdCheck } from 'react-icons/md';
import './TruthIcon.scss';

function TruthIcon() {
  return (
    <div className="icon__outer-circle--green">
      <div className="icon__inner-circle--green">
        <MdCheck className="icon__check-icon" />
      </div>
    </div>
  );
}

export default TruthIcon;
