import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { DeleteIcon, VisibilityIcon, VisibilityOffIcon, PromotionIcon, PromotionOffIcon } from 'assets/icons';
import { useClaimsManagement, Claim } from 'modules/claims';
import './TileAdminControls.scss';

interface TileAdminControlsProps {
  isVisible: boolean;
  claim: Claim;
}

export const TileAdminControls: React.VFC<TileAdminControlsProps> = ({ isVisible, claim }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { deleteClaim, toggleClaimVisibility, toggleClaimPromotion } = useClaimsManagement();

  const toggleVisibilityLabel = claim.isVisible ? t('feed.hideActionLabel') : t('feed.showActionLabel');
  const togglePromotionLabel = claim.isPromoted ? t('feed.unpromoteActionLabel') : t('feed.promoteActionLabel');

  const handleToggleDialog = (): void => {
    setIsDialogOpen(currState => !currState);
  };

  return (
    <>
      <Collapse className="tile__collapse" in={isVisible}>
        <div className="tile__controls">
          <Tooltip title={t('feed.deleteActionLabel')} placement="top" arrow>
            <IconButton onClick={handleToggleDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={toggleVisibilityLabel} placement="top" arrow>
            <IconButton onClick={() => toggleClaimVisibility(claim.id, !claim.isVisible)}>
              {claim.isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title={togglePromotionLabel} placement="top" arrow>
            <IconButton onClick={() => toggleClaimPromotion(claim.id, !claim.isPromoted)}>
              {claim.isPromoted ? <PromotionOffIcon /> : <PromotionIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </Collapse>
      <Dialog open={isDialogOpen} onClose={handleToggleDialog}>
        <DialogTitle>{t('feed.deleteActionLabel')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('feed.deleteConfirmation')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleDialog}>{t('general.cancelAction')}</Button>
          <Button
            onClick={() => {
              deleteClaim(claim.id);
              handleToggleDialog();
            }}
          >
            {t('general.confirmAction')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
