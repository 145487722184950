import React from 'react';
import './Input.scss';

interface InputProps {
  label: string;
  type: string;
  name: string;
  onChange: () => void;
  error: string;
  value?: string;
}
export const Input: React.VFC<InputProps> = ({ label, type, name, onChange, error, value }) => (
  <div>
    <div className="form-control">
      <input className="form-input" value={value} name={name} onChange={onChange} type={type} />
      <label className="form-label">{label}</label>
      {error && <div className="error">{error}</div>}
    </div>
  </div>
);
