import { joiResolver } from '@hookform/resolvers/joi';
import { CardContainer } from 'components/Card';
import Joi from 'joi';
import { useAuthManagement } from 'modules/auth';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { i18nClient } from 'services/translations';
import { Button, Input } from 'shared';
import '../UserSettings.scss';

const changePasswordFormSchema = Joi.object({
  password: Joi.string()
    .trim()
    .min(6)
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.min': i18nClient.t('auth.passwordFormatErrorMessage'),
    }),
  password_confirmation: Joi.string()
    .equal(Joi.ref('password'))
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'any.only': i18nClient.t('auth.passwordNotSameErrorMessage'),
    }),
});

interface ChangePasswordData {
  password: string;
  password_confirmation: string;
}

export const ChangePasswordContainer: React.FC = () => {
  const { errors, handleSubmit, control } = useForm<ChangePasswordData>({
    mode: 'onTouched',
    resolver: joiResolver(changePasswordFormSchema),
  });
  const { t } = useTranslation();
  const { changeUserPassword } = useAuthManagement();

  const isPasswordError = Boolean(errors.password);
  const isPasswordConfirmationError = Boolean(errors.password_confirmation);

  return (
    <CardContainer key={'change-password'}>
      <span className="settings-header__title">{t('customizeAccount.changePasswordLabel')}</span>
      <form noValidate onSubmit={handleSubmit(({ password }) => changeUserPassword(password))}>
        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="password"
              label={t('auth.passwordLabel')}
              value={field.value}
              error={isPasswordError && errors.password!.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password_confirmation"
          defaultValue=""
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="password"
              label={t('auth.retypePasswordLabel')}
              value={field.value}
              error={isPasswordConfirmationError && errors.password_confirmation!.message}
            />
          )}
        />
        <div className="settings-footer">
          <Button type="submit" style="dark">
            {t('customizeAccount.changePasswordButtonLabel')}
          </Button>
        </div>
      </form>
    </CardContainer>
  );
};
