import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './ClaimViewContainer.scss';
import { Helmet } from 'react-helmet';
import { Topbar } from '../../shared';
import { useAuthManagement } from '../../modules/auth';
import { Tile } from '../../features/Claims/components/Tile/Tile';
import { Claim, determineClaimVoteStatus, useClaimsManagement, VoteStatus } from '../../modules/claims';
import { useUserManagement } from '../../modules/user';
import { useTranslation } from 'react-i18next';
import { TilesView } from '../../features/Claims/components/TilesView/TilesView';
import Masonry from 'react-masonry-component';
import { MdCompareArrows } from 'react-icons/md';
import { CircularProgress } from '@material-ui/core';

interface ParamTypes {
  claimId: string;
}
export const ClaimViewContainer: React.VFC = () => {
  const { user, logoutUser } = useAuthManagement();
  const { fetchClaim, fetchClaims, claims, promotedClaims, isSuccess } = useClaimsManagement();
  const { userCalls } = useUserManagement();
  const { claimId } = useParams<ParamTypes>();
  const { t } = useTranslation();

  useEffect(() => {
    fetchClaims();
  }, [claimId, fetchClaim, fetchClaims]);

  useEffect(() => {
    if (isSuccess) {
      fetchClaim(claimId);
    }
  }, [claimId, fetchClaim, isSuccess]);

  const claimsWOSingleClaim = claims.filter(object => {
    return object.id !== claimId;
  });
  const singleClaim =
    claims.find(claim => claim.id === claimId && Object.keys(claim).length > 1) ||
    promotedClaims.find(claim => claim.id === claimId && Object.keys(claim).length > 1);

  const claimDeleted =
    claims.find(claim => claim.id === claimId && Object.keys(claim).length === 1) ||
    promotedClaims.find(claim => claim.id === claimId && Object.keys(claim).length === 1);

  const getBackgroundLabel = useCallback(
    (singleClaim: Claim) => {
      const { voteStatus } = determineClaimVoteStatus(singleClaim);

      if (voteStatus === VoteStatus.Truth) {
        return t('tile.truthLabel');
      }
      if (voteStatus === VoteStatus.Tie) {
        return t('tile.tieLabel');
      }
      if (voteStatus === VoteStatus.Bullshit) {
        return t('tile.bsLabel');
      }
    },
    [t]
  );

  const title = singleClaim ? singleClaim.sentenceText : 'ICallBS: People Decide';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Topbar logout={logoutUser} user={user} />
      <div className="app_container">
        <div className="app_content">
          <div className="single-claim-container">
            {singleClaim ? (
              <>
                <div className="single-claim__bg-label-wrapper">
                  <span className="single-claim__bg-label-text">{getBackgroundLabel(singleClaim)}</span>
                </div>
                <Tile
                  claim={singleClaim}
                  isSingleClaimView
                  userCall={userCalls.find(call => call.claimId === singleClaim.id)}
                />
              </>
            ) : (
              <>
                {claimDeleted ? (
                  <div className="single-claim__deleted">
                    <h2>{t('feed.singleClaimDelete')}</h2>
                    <h3>
                      {t('feed.routingHomePage1')} <a href="/">{t('feed.routingHomePage2')}</a>
                    </h3>
                  </div>
                ) : (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}
              </>
            )}{' '}
          </div>
          <div className="single-claim__related-claims-label-wrapper">
            <MdCompareArrows className="single-claim__related-claims-icon" />
            <span className="single-claim__related-claims-text">Related Claims</span>
          </div>
          <Masonry
            className="tiles-view"
            elementType="ul"
            options={{ itemSelector: '.tile', gutter: 20, fitWidth: true }}
          >
            <TilesView claims={claimsWOSingleClaim} userCalls={userCalls} />
          </Masonry>
        </div>
      </div>
    </>
  );
};
