import React from 'react';
import { MdAttachment } from 'react-icons/md';
import './TileSource.scss';

interface TileSourceProps {
  source: string;
}

export const TileSource: React.FC<TileSourceProps> = ({ source }) => {
  const { hostname } = new URL(source);

  return (
    <div className="tile-source">
      <div>
        <MdAttachment className="tile-source__icon" />
        <span className="tile-source__text">Source</span>
      </div>
      <a className="tile-source__link" href={source} target="_blank" rel="noopener noreferrer">
        {hostname}
      </a>
    </div>
  );
};
