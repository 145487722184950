import { from, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap, filter } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { ofType } from 'modules/store/of-type';
import { Action } from 'modules/store/action';
import { firebaseAuth, firebaseFunctions } from 'services/firebase';
import { AuthAction, AuthActionType } from './actions';
import { user as userStateObserver } from 'rxfire/auth';
import { SharedAction } from 'modules/shared/actions';
import { i18nClient } from 'services/translations';

const startAuthObserverEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.StartAuthObserver),
    switchMap(() =>
      userStateObserver(firebaseAuth).pipe(
        map(user => AuthAction.authObserverEmission(user)),
        catchError(error => of(AuthAction.startAuthObserverFailure(error)))
      )
    )
  );

const authObserverEmissionUserClaimsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.AuthObserverEmission),
    filter(({ payload }) => !!payload.user),
    switchMap(({ payload }) =>
      from(payload.user!.getIdTokenResult(true)).pipe(
        map(idTokenResult => {
          const { isAdmin } = idTokenResult.claims;

          return AuthAction.setUserClaims({ isAdmin });
        })
      )
    )
  );

const loginUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.LoginUser),
    switchMap(({ payload: { credentials } }) =>
      from(firebaseAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
        map(() => AuthAction.loginUserSuccess()),
        catchError(error => {
          return of(AuthAction.loginUserFailure(error));
        })
      )
    )
  );

const logoutUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.LogoutUser),
    switchMap(() =>
      from(firebaseAuth.signOut()).pipe(
        map(() => AuthAction.logoutUserSuccess()),
        catchError(error => {
          return of(AuthAction.logoutUserFailure(error));
        })
      )
    )
  );

const registerUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.RegisterUser),
    switchMap(({ payload: { credentials } }) =>
      from(firebaseAuth.createUserWithEmailAndPassword(credentials.email, credentials.password)).pipe(
        map(() => AuthAction.registerUserSuccess()),
        catchError(error => {
          return of(AuthAction.registerUserFailure(error));
        })
      )
    )
  );

const resetUserPasswordEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.ResetUserPassword),
    switchMap(({ payload }) =>
      from(firebaseAuth.sendPasswordResetEmail(payload.email)).pipe(
        map(() => {
          return AuthAction.resetUserPasswordSuccess();
        }),
        catchError(error => {
          return of(AuthAction.resetUserPasswordFailure(error));
        })
      )
    )
  );

const changeUserPasswordEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.ChangeUserPassword),
    switchMap(({ payload: { newPassword } }) =>
      from(firebaseAuth.currentUser!.updatePassword(newPassword)).pipe(
        mergeMap(() =>
          of(
            SharedAction.notifyUser(i18nClient.t('customizeAccount.changePasswordSuccessMessage')),
            AuthAction.changeUserPasswordSuccess()
          )
        ),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('customizeAccount.customizeAccountFailureMessage'), 'error'),
            AuthAction.changeUserPasswordFailure(error)
          )
        )
      )
    )
  );

const deleteUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.DeleteUser),
    switchMap(() =>
      from(firebaseAuth.currentUser!.delete()).pipe(
        mergeMap(() =>
          of(
            SharedAction.notifyUser(i18nClient.t('customizeAccount.deleteAccountSuccessMessage')),
            AuthAction.deleteUserSuccess()
          )
        ),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('customizeAccount.customizeAccountFailureMessage'), 'error'),
            AuthAction.deleteUserFailure(error)
          )
        )
      )
    )
  );

const verifyCaptchaEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.verifyCaptcha),
    switchMap(({ payload: { token } }) =>
      from(firebaseFunctions.httpsCallable('verifyRecaptcha')({ token })).pipe(
        map(data => AuthAction.verifyCaptchaSuccess(data)),
        catchError(error => {
          return of(AuthAction.verifyCaptchaFailure(error));
        })
      )
    )
  );

export const authEpics = combineEpics(
  loginUserEpic,
  startAuthObserverEpic,
  registerUserEpic,
  resetUserPasswordEpic,
  logoutUserEpic,
  changeUserPasswordEpic,
  deleteUserEpic,
  authObserverEmissionUserClaimsEpic,
  verifyCaptchaEpic
);
