import { createAction } from 'services/store/create-action';
import type { NotificationVariant } from './state';

export enum SharedActionType {
  NotifyUser = '[Shared]: Notify user',
  NotifyUserSuccess = '[Shared]: Notify user - success',
}

export const SharedAction = {
  notifyUser: (message: string, variant?: NotificationVariant) =>
    createAction(SharedActionType.NotifyUser, { message, variant }),
  notifyUserSuccess: () => createAction(SharedActionType.NotifyUserSuccess),
};

export type SharedActionCreator = typeof SharedAction;
