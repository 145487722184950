import type firebase from 'firebase';

export type PersonalInfoType = {
  name?: string;
  age?: number;
  location?: string;
};

export type UserData = {
  lastVisited: string[];
  bookmarks: string[];
  starredClaimsIds: string[];
  reportedClaims: ReportedClaim[];
  hiddenClaimsIds: string[];
  userPersonalInfo?: PersonalInfoType;
};

export type Author = {
  id: string;
  avatar: string | null;
  name: string | null;
};

export enum CallVoteType {
  Truth = 'truth',
  Bullshit = 'bullshit',
}

export type Call = {
  id: string;
  claimId: string;
  createdAt: firebase.firestore.Timestamp;
  modifiedAt: firebase.firestore.Timestamp;
  author: Author;
  voteType: CallVoteType;
};

export type ReportedClaim = {
  claimId: string;
  reason: string;
  createdAt: firebase.firestore.Timestamp;
};
export interface Report extends ReportedClaim {
  userId: string;
}

export interface ReportedClaims {
  reportedClaims: Report;
}
