import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { i18nClient } from 'services/translations';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import logo from '../../../../assets/icbs_logo.svg';
import { Button, Icon, Input } from '../../../../shared';
import './Register.scss';
import { useLayoutManagement } from '../../../../modules/layout/hooks';
import { useAuthManagement } from '../../../../modules/auth';

interface RegistrationFormData {
  email: string;
  password: string;
  password_confirmation: string;
}

const registerFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
  password: Joi.string()
    .trim()
    .min(6)
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.min': i18nClient.t('auth.passwordFormatErrorMessage'),
    }),
  password_confirmation: Joi.string()
    .equal(Joi.ref('password'))
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'any.only': i18nClient.t('auth.passwordNotSameErrorMessage'),
    }),
}).required();

// TODO: add translation support
export const Register: React.VFC = () => {
  const { showModal, hideModal } = useLayoutManagement();
  const { user, registerUser, isErrored, resetAuthErrors, verifyCaptcha, setTokenExpired, isTokenVerified } =
    useAuthManagement();

  const history = useHistory();

  const { errors, handleSubmit, control } = useForm<RegistrationFormData>({
    mode: 'onTouched',
    resolver: joiResolver(registerFormSchema),
  });
  const isEmailError = Boolean(errors.email);
  const isPasswordError = Boolean(errors.password);
  const isPasswordConfirmError = Boolean(errors.password_confirmation);

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
      if (isErrored) {
        resetAuthErrors();
      }
    };
  }, [hideModal, isErrored, resetAuthErrors, showModal]);

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, user]);

  const handleExit = () => history.push('/');

  const handleReCaptchaVerify = (token: string | null): void => {
    if (token) {
      verifyCaptcha(token);
    }
  };

  return (
    <>
      <div className="register">
        <div className="register__topbar">
          <img className="register__logo" alt="I call BS" src={logo} />
          <Icon className="register__icon-right" type="clear" onClick={handleExit} />
        </div>
        <div>
          <h2 className="register__header">Register</h2>
        </div>
        <div>
          <form onSubmit={handleSubmit(registerUser)}>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={field => (
                <Input
                  name={field.name}
                  onChange={field.onChange}
                  type="text"
                  label="Email"
                  value={field.value}
                  error={isEmailError && errors.email!.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={field => (
                <Input
                  name={field.name}
                  onChange={field.onChange}
                  type="password"
                  label="Password"
                  value={field.value}
                  error={isPasswordError && errors.password!.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password_confirmation"
              defaultValue=""
              render={field => (
                <Input
                  name={field.name}
                  onChange={field.onChange}
                  type="password"
                  label="Retype Password"
                  value={field.value}
                  error={isPasswordConfirmError && errors.password_confirmation!.message}
                />
              )}
            />
            {isErrored && <div className="error">{i18nClient.t('general.failureMessage')}</div>}
            <Link className="register__lost-pass" to="/lost-password">
              Lost password
            </Link>

            <div className="register__buttons">
              <Tooltip
                title={!isTokenVerified ? ' Please verify that you are a human to create an account.' : ''}
                placement="right"
                arrow
              >
                <div>
                  <Button icon="person_add" type="submit" style="dark" disabled={!isTokenVerified}>
                    Create Account
                  </Button>
                </div>
              </Tooltip>

              <Button link="/login" style="light">
                I have an account
              </Button>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                onChange={handleReCaptchaVerify}
                onExpired={setTokenExpired}
                className="g-recaptcha"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="login__backdrop" onClick={handleExit} />
    </>
  );
};
