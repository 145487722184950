import { Action } from 'modules/store';
import { LayoutActionType } from './actions';
import { LayoutState, ViewTypes } from './types';

export const layoutState: LayoutState = {
  isModalShown: false,
  viewType: ViewTypes.tiles,
};

export const layoutReducer = (state = layoutState, action: Action): LayoutState => {
  switch (action.type) {
    case LayoutActionType.ShowModal:
      return {
        ...state,
        isModalShown: true,
      };
    case LayoutActionType.HideModal:
      return {
        ...state,
        isModalShown: false,
      };
    case LayoutActionType.SetViewType:
      return {
        ...state,
        viewType: action.payload.viewType,
      };
    default:
      return state;
  }
};
