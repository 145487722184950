import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from 'assets/icbs_logo.svg';
import { Button } from '../Button/Button';
import type firebase from 'firebase/app';
import './Topbar.scss';
import { useTranslation } from 'react-i18next';
import { useAuthManagement } from 'modules/auth';
import { ClaimDialog } from 'components/ClaimDialog';
import { MenuIcon } from 'assets/icons';
import { useMediaQuery } from 'react-responsive';

interface TopbarProps {
  user: firebase.User;
  logout: () => void;
}

export const Topbar: React.FC<TopbarProps> = ({ children, user, logout }) => {
  const history = useHistory();
  // const { userName } = useUserManagement();
  const { isAdmin } = useAuthManagement();
  const { t } = useTranslation();
  const [isDialogVisible, setDialogVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
  const showAddClaim = isAdmin && user;
  const handleDialogToggle = () => setDialogVisible(!isDialogVisible);
  return (
    <div className="topbar-container">
      <div className="topbar">
        <div className="topbar-first">
          <Link to="/">
            <img className="topbar-logo" alt="ICBS" src={logo} />
          </Link>
          {children}
        </div>
        <div className="topbar-right">
          {/* Links to plugin and mobile app temporarily disabled. Links will reappear after arrangements
            regarding the plugin and publication of the mobile app */}

          {/* <li className="topbar-nav__item clickable topbar-nav__item-highlight">
              <Link className="topbar-nav__item-link topbar-nav__item--grey" to="#">
                {t('topBar.pluginLabel')}
              </Link>
            </li>
            <li className="topbar-nav__item clickable topbar-nav__item-highlight">
              <Link className="topbar-nav__item-link topbar-nav__item--grey" to="#">
                {t('topBar.mobileAppLabel')}
              </Link>
            </li> */}
          {!user && (
            <ul className="topbar-nav">
              <Button icon="done" link="/login" style="dark">
                {t('topBar.loginLabel')}
              </Button>
              <Button icon="person_add" link="/register" style="light">
                {t('topBar.createAccountLabel')}
              </Button>
              <li className="topbar-nav__item clickable topbar-nav__item-highlight">
                <Link className="topbar-nav__item-link topbar-nav__item--grey" to="/about">
                  {t('topBar.about')}
                </Link>
              </li>
            </ul>
          )}
          {!isMobile ? (
            <>
              <ul className="topbar-nav">
                {user && (
                  <li className="topbar-nav__item clickable topbar-nav__item-highlight">
                    <Link className="topbar-nav__item-link topbar-nav__item--grey" to="/activity">
                      {t('topBar.activityLabel')}
                    </Link>
                  </li>
                )}
                {showAddClaim && (
                  <>
                    <li className="topbar-nav__item clickable topbar-nav__item-highlight">
                      <Link className="topbar-nav__item-link topbar-nav__item--grey" to="/rss">
                        {t('topBar.rssLabel')}
                      </Link>
                    </li>
                    <li className="topbar-nav__item clickable topbar-nav__item-highlight">
                      <Button style="dark" onClick={handleDialogToggle}>
                        {t('topBar.addCustomClaimLabel')}
                      </Button>
                    </li>
                  </>
                )}
              </ul>
              {user && (
                <div className="userinfo">
                  <MenuIcon />
                  <div className="userinfo-menu">
                    <span onClick={() => history.push('/settings')}>{t('topBar.settingsLabel')}</span>
                    <span onClick={() => history.push('/about')}>{t('topBar.about')}</span>
                    <span onClick={logout}>{t('topBar.logoutLabel')}</span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {user && (
                <>
                  <ul className="topbar-nav">
                    {showAddClaim && (
                      <li className="topbar-nav__item clickable topbar-nav__item-highlight">
                        <Button style="dark" onClick={handleDialogToggle}>
                          {t('topBar.addCustomClaimLabel')}
                        </Button>
                      </li>
                    )}
                  </ul>
                  <div className="userinfo">
                    <MenuIcon />
                    <div className="userinfo-menu">
                      <span onClick={() => history.push('/activity')}>{t('topBar.activityLabel')}</span>
                      <span onClick={() => history.push('/rss')}>{t('topBar.rssLabel')}</span>
                      <span onClick={() => history.push('/settings')}>{t('topBar.settingsLabel')}</span>
                      <span onClick={() => history.push('/about')}>{t('topBar.about')}</span>
                      <span onClick={logout}>{t('topBar.logoutLabel')}</span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {isDialogVisible && <ClaimDialog isDialogVisible={isDialogVisible} handleDialogToggle={handleDialogToggle} />}
        </div>
      </div>
    </div>
  );
};
