import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { NotificationVariant } from './state';
import { SharedAction } from './actions';
import { selectNotificationQueue } from './selectors';

export const useSharedManagement = () => {
  const dispatch = useDispatch();
  const notificationQueue = useSelector(selectNotificationQueue);

  const notifyUser = useCallback(
    (message: string, variant?: NotificationVariant) => {
      dispatch(SharedAction.notifyUser(message, variant));
    },
    [dispatch]
  );

  const notifyUserSuccess = useCallback(() => {
    dispatch(SharedAction.notifyUserSuccess());
  }, [dispatch]);

  return {
    notificationQueue,

    notifyUser,
    notifyUserSuccess,
  };
};
