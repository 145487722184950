import { RefObject, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusFailure, isStatusPending, isStatusSuccessPending, isStatusSuccess } from 'modules/store/status';
import { ClaimsAction } from './actions';
import { selectClaimsList, selectPromotedClaimsList } from './selectors';
import { Claim, QueryFilterType } from './types';
import { ClaimsCategory } from 'features/Claims/consts';

export const useClaimsManagement = () => {
  const dispatch = useDispatch();

  const claimsList = useSelector(selectClaimsList);
  const promotedCLaimsList = useSelector(selectPromotedClaimsList);

  const isLoading = isStatusPending(claimsList.status);
  const isFailure = isStatusFailure(claimsList.status);
  const isRefetching = isStatusSuccessPending(claimsList.status);
  const isSuccess = isStatusSuccess(claimsList.status);
  const category = claimsList.category;
  const hasNextPage = claimsList.hasNextPage;

  const fetchClaims = useCallback(
    (queryFilter?: QueryFilterType, queryCursor?: Claim, queryCategory?: ClaimsCategory) => {
      if (!isLoading) {
        dispatch(ClaimsAction.fetchClaims(queryFilter, queryCursor, queryCategory));
      }
    },
    [dispatch, isLoading]
  );

  const fetchClaim = useCallback(
    (claimId: string) => {
      dispatch(ClaimsAction.fetchClaim(claimId));
    },
    [dispatch]
  );

  const fetchPromotedClaims = useCallback(() => {
    dispatch(ClaimsAction.fetchPromotedClaims());
  }, [dispatch]);

  const deleteClaim = useCallback(
    (claimId: string) => {
      dispatch(ClaimsAction.deleteClaim(claimId));
    },
    [dispatch]
  );

  const toggleClaimVisibility = useCallback(
    (claimId: string, isVisible: boolean) => {
      dispatch(ClaimsAction.toggleClaimVisibility(claimId, isVisible));
    },
    [dispatch]
  );

  const toggleClaimPromotion = useCallback(
    (claimId: string, isPromoted: boolean) => {
      dispatch(ClaimsAction.toggleClaimPromotion(claimId, isPromoted));
    },
    [dispatch]
  );

  const saveCustomClaim = useCallback(
    (claimText: string, claimedBy: string, claimUrl: string | null, category: string) => {
      dispatch(ClaimsAction.saveCustomClaim(claimText, claimedBy, claimUrl, category));
    },
    [dispatch]
  );
  const useInfiniteScroll = (
    queryCursor?: Claim,
    queryFilter?: QueryFilterType
  ): [RefObject<HTMLDivElement>, (node: HTMLDivElement) => void] => {
    const ref = useRef<HTMLDivElement | null>(null);
    const setRef = useCallback(node => {
      ref.current = node;
    }, []);
    useObserver(ref.current, queryCursor);
    return [ref, setRef];
  };
  const observer = useRef<IntersectionObserver>();
  const useObserver = useCallback(
    (node: HTMLDivElement, queryCursor?: Claim, queryFilter?: QueryFilterType) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isLoading && !isRefetching && hasNextPage) {
          dispatch(ClaimsAction.fetchClaims(queryFilter, queryCursor, category));
        }
      });
      if (node) observer.current.observe(node);
    },
    [dispatch, isLoading, isRefetching, category, hasNextPage]
  );

  const changeClaimsCategory = useCallback(
    (claimsCategory: ClaimsCategory) => {
      dispatch(ClaimsAction.changeClaimsCategory(claimsCategory));
    },
    [dispatch]
  );

  return {
    isLoading,
    isFailure,
    isRefetching,
    isSuccess,
    claims: claimsList.data,
    promotedClaims: promotedCLaimsList.data,
    queryCursor: claimsList.queryCursor,
    category,

    fetchClaim,
    fetchClaims,
    deleteClaim,
    toggleClaimVisibility,
    toggleClaimPromotion,
    fetchPromotedClaims,
    saveCustomClaim,
    useInfiniteScroll,
    changeClaimsCategory,
  };
};
