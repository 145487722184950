import React from 'react';
import { createPolygonPoints } from 'modules/claims/utils';
import { VoteStatus } from 'modules/claims';
import './Bullshit.scss';

type BullshitProps = {
  votePercentage: number;
  barWidth: number;
};
const Bullshit: React.VFC<BullshitProps> = ({ votePercentage, barWidth }) => {
  const polygons = createPolygonPoints(barWidth, VoteStatus.Bullshit, votePercentage);

  return (
    <>
      <span className="tile-rate__result tile-rate__result--bullshit">
        {votePercentage}
        <span className="tile-rate__percent">%</span>
      </span>
      <svg className="tile-rate__progress-bar" height="40" width={barWidth}>
        <polygon className="tile-rate__bar--light-red" points={polygons.left} />
        <polygon className="tile-rate__bar--red" points={polygons.right} />
      </svg>
    </>
  );
};

export default Bullshit;
