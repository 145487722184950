import * as React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { FeedContainer } from 'scenes/Feed';
import { ActivityContainer } from 'scenes/Activity';
import NotFound from 'scenes/NotFound';
import './App.scss';
import { LaunchObservers } from '../../components/LaunchObservers';
import { SettingsContainer } from 'scenes/Settings';
import { NotificationOutlet } from '../../components/NotificationOutlet';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { ClaimViewContainer } from 'scenes/Claim';
import { RssContainer } from 'scenes/Rss';
import { AboutContainer } from 'scenes/About';

const App: React.VFC = () => {
  return (
    <LaunchObservers>
      <NotificationOutlet />

      <Switch>
        <Route path={'/(|login|register|lost-password|channel)'} component={FeedContainer} />
        <ProtectedRoute path="/activity" component={ActivityContainer} />
        <ProtectedRoute path="/(settings)" component={SettingsContainer} />
        <ProtectedRoute path="/rss" component={RssContainer} />
        <Route path="/about" component={AboutContainer} />
        <Route path="/claims/:claimId" component={ClaimViewContainer} />
        <Route path="*" component={NotFound} />
      </Switch>
    </LaunchObservers>
  );
};

export default withRouter(App);
