import React from 'react';
import './TileClaim.scss';

interface IProps {
  text: string;
}

export function TileClaim({ text }: IProps) {
  return (
    <div className="tile-claim-container">
      <blockquote className="tile-claim">
        <span className="tile-claim__quote">” </span>
        <p className="tile-claim__text">
          {text} <span className="tile-claim__quote close-quote">”</span>
        </p>
      </blockquote>
    </div>
  );
}
