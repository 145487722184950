import { Action, Status, isStatusSuccess } from 'modules/store';
import { UserActionType } from './actions';
import type { UserState } from './state';

export const userState: UserState = {
  userVotedClaims: {
    data: [],
    status: Status.DEFAULT,
    error: null,
  },
  userStarredClaims: {
    data: [],
    status: Status.DEFAULT,
    error: null,
  },
  userAddedClaims: {
    data: [],
    status: Status.DEFAULT,
    error: null,
  },
  userData: {
    lastVisited: [],
    bookmarks: [],
    starredClaimsIds: [],
    hiddenClaimsIds: [],
    reportedClaims: [],
    userPersonalInfo: {},
    status: Status.DEFAULT,
    error: null,
  },
  userCalls: {
    data: [],
    status: Status.DEFAULT,
    error: null,
  },

  deleteUserCallStatus: Status.DEFAULT,
  swapUserCallStatus: Status.DEFAULT,
};

export const userReducer = (state = userState, action: Action): UserState => {
  switch (action.type) {
    /**
     * FetchUserData
     */
    case UserActionType.FetchUserData:
      return {
        ...state,
        userData: {
          ...state.userData,
          status: isStatusSuccess(state.userData.status) ? Status.SUCCESS_PENDING : Status.PENDING,
          error: null,
        },
      };

    case UserActionType.FetchUserDataSuccess:
      return {
        ...state,
        userData: {
          lastVisited: action.payload.userData.lastVisited,
          bookmarks: action.payload.userData.bookmarks,
          starredClaimsIds: action.payload.userData.starredClaimsIds,
          hiddenClaimsIds: action.payload.userData.hiddenClaimsIds,
          reportedClaims: action.payload.userData.reportedClaims,
          userPersonalInfo: action.payload.userData.userPersonalInfo,
          status: Status.SUCCESS,
          error: null,
        },
      };

    case UserActionType.FetchUserDataFailure:
      return {
        ...state,
        userData: {
          ...state.userData,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * FetchUserVotedClaims
     */
    case UserActionType.FetchUserVotedClaims:
      return {
        ...state,
        userVotedClaims: {
          ...state.userVotedClaims,
          status: isStatusSuccess(state.userVotedClaims.status) ? Status.SUCCESS_PENDING : Status.PENDING,
          error: null,
        },
      };

    case UserActionType.FetchUserVotedClaimsSuccess:
      return {
        ...state,
        userVotedClaims: {
          data: action.payload.userVotedClaims,
          status: Status.SUCCESS,
          error: null,
        },
      };

    case UserActionType.FetchUserVotedClaimsFailure:
      return {
        ...state,
        userVotedClaims: {
          ...state.userVotedClaims,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * FetchUserStarredClaims
     */
    case UserActionType.FetchUserStarredClaims:
      return {
        ...state,
        userStarredClaims: {
          ...state.userStarredClaims,
          status: isStatusSuccess(state.userStarredClaims.status) ? Status.SUCCESS_PENDING : Status.PENDING,
          error: null,
        },
      };

    case UserActionType.FetchUserStarredClaimsSuccess:
      return {
        ...state,
        userStarredClaims: {
          data: action.payload.userStarredClaims,
          status: Status.SUCCESS,
          error: null,
        },
      };

    case UserActionType.FetchUserStarredClaimsFailure:
      return {
        ...state,
        userStarredClaims: {
          ...state.userStarredClaims,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    case UserActionType.FetchUserAddedClaims:
      return {
        ...state,
        userAddedClaims: {
          ...state.userAddedClaims,
          status: isStatusSuccess(state.userAddedClaims.status) ? Status.SUCCESS_PENDING : Status.PENDING,
          error: null,
        },
      };

    case UserActionType.FetchUserAddedClaimsSuccess:
      return {
        ...state,
        userAddedClaims: {
          data: action.payload.userAddedClaims,
          status: Status.SUCCESS,
          error: null,
        },
      };

    case UserActionType.FetchUserAddedClaimsFailure:
      return {
        ...state,
        userAddedClaims: {
          ...state.userAddedClaims,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };
    /**
     * FetchUserCalls
     */
    case UserActionType.FetchUserCalls:
      return {
        ...state,
        userCalls: {
          ...state.userCalls,
          status: Status.PENDING,
        },
      };

    case UserActionType.FetchUserCallsSuccess:
      return {
        ...state,
        userCalls: {
          data: action.payload.calls,
          status: Status.SUCCESS,
          error: null,
        },
      };

    case UserActionType.FetchUserCallsFailure:
      return {
        ...state,
        userCalls: {
          ...state.userCalls,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    // NOTE: commented until we introduce these features in other places in codebase
    /**
     * DeleteUserCall
     */
    case UserActionType.DeleteUserCall:
      return {
        ...state,
        deleteUserCallStatus: Status.PENDING,
      };

    case UserActionType.DeleteUserCallSuccess:
      return {
        ...state,
        deleteUserCallStatus: Status.SUCCESS,
      };

    case UserActionType.DeleteUserCallFailure:
      return {
        ...state,
        deleteUserCallStatus: Status.FAILURE,
      };

    /**
     * SwapUserCall
     */
    case UserActionType.SwapUserCall:
      return {
        ...state,
        swapUserCallStatus: Status.PENDING,
      };

    case UserActionType.SwapUserCallSuccess:
      return {
        ...state,
        swapUserCallStatus: Status.SUCCESS,
      };

    case UserActionType.SwapUserCallFailure:
      return {
        ...state,
        swapUserCallStatus: Status.FAILURE,
      };

    /**
     * FetchUserRelatedClaim
     */
    case UserActionType.FetchUserRelatedClaim:
      return {
        ...state,
        userVotedClaims: {
          ...state.userVotedClaims,
          status: isStatusSuccess(state.userVotedClaims.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
        userStarredClaims: {
          ...state.userStarredClaims,
          status: isStatusSuccess(state.userStarredClaims.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
      };

    case UserActionType.FetchUserRelatedClaimSuccess: {
      const starredClaimsIndex = state.userStarredClaims.data.findIndex(claim => claim.id === action.payload.claim.id);
      const votedClaimsIndex = state.userVotedClaims.data.findIndex(claim => claim.id === action.payload.claim.id);
      return {
        ...state,
        userVotedClaims: {
          ...state.userVotedClaims,
          data:
            votedClaimsIndex === -1
              ? [...state.userVotedClaims.data]
              : [
                  ...state.userVotedClaims.data.slice(0, votedClaimsIndex),
                  action.payload.claim,
                  ...state.userVotedClaims.data.slice(votedClaimsIndex + 1),
                ],
          status: Status.SUCCESS,
        },
        userStarredClaims: {
          ...state.userStarredClaims,
          data:
            starredClaimsIndex === -1
              ? [...state.userStarredClaims.data]
              : [
                  ...state.userStarredClaims.data.slice(0, starredClaimsIndex),
                  action.payload.claim,
                  ...state.userStarredClaims.data.slice(starredClaimsIndex + 1),
                ],
          status: Status.SUCCESS,
        },
      };
    }

    case UserActionType.FetchUserRelatedClaimFailure:
      return {
        ...state,
        userVotedClaims: {
          ...state.userVotedClaims,
          status: Status.FAILURE,
        },
        userStarredClaims: {
          ...state.userStarredClaims,
          status: Status.FAILURE,
        },
      };

    default:
      return state;
  }
};
