import firebase from 'firebase/app';
import { firebaseFirestore } from 'services/firebase';
import { UserData, Call, ReportedClaims } from './types';

export const createUserDocumentRefRead = (userId: string) => {
  return firebaseFirestore.collection('users').doc(userId) as firebase.firestore.DocumentReference<UserData>;
};

export const createUserDocumentRefWrite = (userId: string) => {
  return firebaseFirestore.collection('users').doc(userId) as firebase.firestore.DocumentReference<Partial<UserData>>;
};

export const createUserCallsCollectionRef = (userId: string) => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('calls') as firebase.firestore.CollectionReference<Call>;
};

export const createUserCallDocumentRef = (userId: string, callId?: string) => {
  if (callId) {
    return createUserCallsCollectionRef(userId).doc(callId) as firebase.firestore.DocumentReference<Call>;
  }

  return createUserCallsCollectionRef(userId).doc() as firebase.firestore.DocumentReference<Call>;
};

export const createCallsCollectionGroup = (claimId: string) =>
  firebaseFirestore.collectionGroup('calls').where('claimId', '==', claimId) as firebase.firestore.Query<Call>;

export const createUsersWithStarredClaimsCollectionGroup = (claimId: string) =>
  firebaseFirestore
    .collection('users')
    .where('starredClaimsIds', 'array-contains', claimId) as firebase.firestore.Query<UserData>;

export const createReportedClaimDocumentRef = (claimId: string) => {
  return firebaseFirestore.collection('reports').doc(claimId) as firebase.firestore.DocumentReference<
    Partial<ReportedClaims>
  >;
};
