import type { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { AuthActionType } from './actions';
import type { AuthState } from './state';

export const authState: AuthState = {
  user: {
    data: null,
    error: null,
  },
  login: {
    status: Status.DEFAULT,
    error: null,
  },
  register: {
    status: Status.DEFAULT,
    error: null,
    isTokenVerified: false,
  },
  resetPassword: {
    status: Status.DEFAULT,
    error: null,
  },
  changeUserPassword: {
    status: Status.DEFAULT,
    error: null,
  },
  delete: {
    status: Status.DEFAULT,
    error: null,
  },
};

export function authReducer(state = authState, action: Action): AuthState {
  switch (action.type) {
    /**
     * AuthObserverEmission
     */
    case AuthActionType.AuthObserverEmission:
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.user,
          error: null,
        },
      };

    case AuthActionType.StartAuthObserverFailure:
      return {
        ...state,
        user: {
          ...state.user,
          error: action.payload.error,
        },
      };

    /**
     * LoginUser
     */
    case AuthActionType.LoginUser:
      return {
        ...state,
        login: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.LoginUserSuccess:
      return {
        ...state,
        login: {
          ...state.login,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.LoginUserFailure:
      return {
        ...state,
        login: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * RegisterUser
     */
    case AuthActionType.RegisterUser:
      return {
        ...state,
        register: {
          status: Status.PENDING,
          error: null,
          ...state.register,
        },
      };

    case AuthActionType.RegisterUserSuccess:
      return {
        ...state,
        register: {
          ...state.register,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.verifyCaptchaSuccess:
      const { data } = action.payload.recaptchaResponse;
      return {
        ...state,
        register: {
          ...state.register,
          isTokenVerified: data.success,
        },
      };

    case AuthActionType.verifyCaptchaFailure:
      return {
        ...state,
        register: {
          ...state.register,
          isTokenVerified: false,
          error: action.payload.error,
        },
      };

    case AuthActionType.clearRecaptchaToken:
      return {
        ...state,
        register: {
          ...state.register,
          isTokenVerified: false,
        },
      };

    /**
     * ResetUserPassword
     */
    case AuthActionType.ResetUserPassword:
      return {
        ...state,
        resetPassword: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.ResetUserPasswordSuccess:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.ResetUserPasswordFailure:
      return {
        ...state,
        resetPassword: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * ResetAuthErrors
     */
    case AuthActionType.ResetAuthErrors:
      return {
        ...state,
        login: {
          status: Status.DEFAULT,
          error: null,
        },
        register: {
          status: Status.DEFAULT,
          error: null,
          isTokenVerified: false,
        },
        resetPassword: {
          status: Status.DEFAULT,
          error: null,
        },
      };

    /**
     * Change User Password
     */
    case AuthActionType.ChangeUserPassword:
      return {
        ...state,
        changeUserPassword: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.ChangeUserPasswordSuccess:
      return {
        ...state,
        changeUserPassword: {
          ...state.changeUserPassword,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.ChangeUserPasswordFailure:
      return {
        ...state,
        changeUserPassword: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * Delete User
     */
    case AuthActionType.DeleteUser:
      return {
        ...state,
        delete: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.DeleteUserSuccess:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.DeleteUserFailure:
      return {
        ...state,
        delete: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * SetUserClaims
     */
    case AuthActionType.SetUserClaims:
      return {
        ...state,
        user: {
          ...state.user,
          claims: action.payload.userClaims,
        },
      };

    default:
      return state;
  }
}
