import React, { useCallback } from 'react';
import type firebase from 'firebase/app';
import { MdGroup, MdShare, MdStar, MdStarBorder } from 'react-icons/md';

import BSIcon from '../BSIcon/BSIcon';
import TruthIcon from '../TruthIcon/TruthIcon';
import { VoteStatus } from 'modules/claims';
import './TileResult.scss';
import { useUserManagement } from '../../../../../modules/user';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSharedManagement } from '../../../../../modules/shared/hooks';
import { SocialsShare } from '../SocialsShare/SocialsShare';

interface TileResultProps {
  voteStatus: VoteStatus;
  voteTotalCount: number;
  claimCreatedAt: firebase.firestore.Timestamp;
  claimId: string;
}

export const TileResult: React.FC<TileResultProps> = ({ voteStatus, claimId, voteTotalCount, claimCreatedAt }) => {
  const { saveUserStarredClaimId, deleteUserStarredClaimId, checkIfStarred } = useUserManagement();
  const { t } = useTranslation();
  const { notifyUser } = useSharedManagement();
  const isStarred = checkIfStarred(claimId);

  const handleSwitchStarredIcon = useCallback(() => {
    if (!isStarred) {
      saveUserStarredClaimId(claimId);
    } else {
      deleteUserStarredClaimId(claimId);
    }
  }, [claimId, deleteUserStarredClaimId, isStarred, saveUserStarredClaimId]);

  const renderStarIcon = useCallback(
    () =>
      isStarred ? (
        <MdStar className="tile-header__second-section__star-icon" onClick={handleSwitchStarredIcon} />
      ) : (
        <MdStarBorder className="tile-header__second-section__star-icon" onClick={handleSwitchStarredIcon} />
      ),
    [handleSwitchStarredIcon, isStarred]
  );

  return (
    <div className="tile-header">
      <div className="tile-header__first-section">
        <div>
          <MdGroup className="tile-header__first-section__group-icon" />
          <span className="tile-header__first-section__text">
            {t('tile.totalVotesLabel')} {voteTotalCount}
          </span>
        </div>
        <span className="tile-header__first-section__time">
          {formatDistance(claimCreatedAt.toDate(), new Date(), { addSuffix: true })}
        </span>
      </div>
      <div className="tile-header__second-section">
        {voteStatus === VoteStatus.Truth && (
          <div className="tile-header__second-section__result-wrapper">
            <TruthIcon />
            <span className="tile-header__second-section__text">{t('tile.truthLabel')}</span>
          </div>
        )}

        {voteStatus === VoteStatus.Tie && (
          <div className="tile-header__second-section__result-wrapper">
            <div className="tile-header__second-section__result-wrapper__tie">
              <TruthIcon />
              <BSIcon />
            </div>

            <span className="tile-header__second-section__text">{t('tile.tieLabel')}</span>
          </div>
        )}

        {voteStatus === VoteStatus.Bullshit && (
          <div className="tile-header__second-section__result-wrapper">
            <BSIcon />
            <span className="tile-header__second-section__text">{t('tile.bsLabel')}</span>
          </div>
        )}
        <div className="tile-header__second-section__result-wrapper">
          {renderStarIcon()}
          <MdShare
            className="tile-header__second-section__share-icon"
            onClick={() => {
              navigator.clipboard
                .writeText(`${window.location.origin}/claims/${claimId}`)
                .then(() => notifyUser(t('tile.copiedToClipboardMessage'), 'success'));
            }}
          />
          <SocialsShare claimId={claimId} />
        </div>
      </div>
    </div>
  );
};
