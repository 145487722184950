import React, { useCallback } from 'react';
import type firebase from 'firebase/app';
import { MdGroup, MdShare, MdStar, MdStarBorder, MdCircle } from 'react-icons/md';
import BSIcon from '../../Tile/BSIcon/BSIcon';
import TruthIcon from '../../Tile/TruthIcon/TruthIcon';
import { VoteStatus } from 'modules/claims';
import './ListTitleResult.scss';
import { useUserManagement } from '../../../../../modules/user';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSharedManagement } from '../../../../../modules/shared/hooks';
import { SocialsShare } from '../../Tile//SocialsShare/SocialsShare';

interface ListTileResultProps {
  voteStatus: VoteStatus;
  voteTotalCount: number;
  claimCreatedAt: firebase.firestore.Timestamp;
  claimId: string;
  claimedBy: string;
  claimCategory?: string;
}

export const ListTitleResult: React.FC<ListTileResultProps> = ({
  voteStatus,
  claimId,
  voteTotalCount,
  claimCreatedAt,
  claimedBy,
  claimCategory,
}) => {
  const { saveUserStarredClaimId, deleteUserStarredClaimId, checkIfStarred } = useUserManagement();
  const { t } = useTranslation();
  const { notifyUser } = useSharedManagement();
  const isStarred = checkIfStarred(claimId);

  const handleSwitchStarredIcon = useCallback(() => {
    if (!isStarred) {
      saveUserStarredClaimId(claimId);
    } else {
      deleteUserStarredClaimId(claimId);
    }
  }, [claimId, deleteUserStarredClaimId, isStarred, saveUserStarredClaimId]);

  const renderStarIcon = useCallback(
    () =>
      isStarred ? (
        <MdStar className="tile-header__second-section__star-icon" onClick={handleSwitchStarredIcon} />
      ) : (
        <MdStarBorder className="tile-header__second-section__star-icon" onClick={handleSwitchStarredIcon} />
      ),
    [handleSwitchStarredIcon, isStarred]
  );

  return (
    <div className="list-tile-header">
      <div className="list-tile-header__first-section">
        <div className="list-title-header__first-section__votes">
          <MdGroup className="list-tile-header__first-section__group-icon" />
          <span className="list-tile-header__first-section__text">
            {t('tile.totalVotesLabel')} {voteTotalCount}
          </span>
        </div>
        <div className="list-tile-header__first-section__result-wrapper">
          {renderStarIcon()}
          <MdShare
            className="list-tile-header__first-section__share-icon"
            onClick={() => {
              navigator.clipboard
                .writeText(`${window.location.origin}/claims/${claimId}`)
                .then(() => notifyUser(t('tile.copiedToClipboardMessage'), 'success'));
            }}
          />
          <SocialsShare claimId={claimId} />
        </div>
      </div>
      <div className="list-tile-header__second-section">
        {voteStatus === VoteStatus.Truth && (
          <div className="list-tile-header__second-section__result-wrapper">
            <TruthIcon />
            <span className="list-tile-header__second-section__text">{t('tile.truthLabel')}</span>
          </div>
        )}
        {voteStatus === VoteStatus.Tie && (
          <div className="list-tile-header__second-section__result-wrapper">
            <div className="list-tile-header__second-section__result-wrapper__tie">
              <TruthIcon />
              <BSIcon />
            </div>

            <span className="list-tile-header__second-section__text">{t('tile.tieLabel')}</span>
          </div>
        )}

        {voteStatus === VoteStatus.Bullshit && (
          <div className="list-tile-header__second-section__result-wrapper">
            <BSIcon />
            <span className="list-tile-header__second-section__text">{t('tile.bsLabel')}</span>
          </div>
        )}
        <div className="list-title-header__second-section-info">
          {claimedBy && (
            <>
              <span>{claimedBy}</span>
              <MdCircle />
            </>
          )}
          {claimCategory && (
            <>
              <span>{claimCategory}</span>
              <MdCircle />
            </>
          )}
          <span className="list-tile-header__second-section__time">
            {formatDistance(claimCreatedAt.toDate(), new Date(), { addSuffix: true })}
          </span>
        </div>
      </div>
    </div>
  );
};
