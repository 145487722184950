import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthManagement } from 'modules/auth/hooks';

export const ProtectedRoute: React.FC<RouteProps> = props => {
  const { user } = useAuthManagement();

  if (user) {
    return <Route {...props} />;
  }

  return <Redirect to="/" />;
};
