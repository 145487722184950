import { ClaimsCategory } from 'features/Claims/consts';
import type firebase from 'firebase/app';
import { compose } from 'redux';
import { firebaseFirestore } from 'services/firebase';
import { Claim, QueryFilterType } from './types';

type CollectionReference<T> = firebase.firestore.CollectionReference<T>;
type Query<T> = firebase.firestore.Query<T>;

export const claimsCollectionRef = firebaseFirestore
  .collection('feed')
  .doc('single')
  .collection('claims') as firebase.firestore.CollectionReference<Claim>;

export const customClaimCollectionRef = firebaseFirestore
  .collection('feed')
  .doc('single')
  .collection('claims') as firebase.firestore.CollectionReference<Partial<Claim>>;

export const promotedClaimsCollectionRef = firebaseFirestore
  .collection('feed')
  .doc('single')
  .collection('claims')
  .where('isPromoted', '==', true) as firebase.firestore.CollectionReference<Claim>;

export const createClaimDocumentRef = (claimId: string) =>
  claimsCollectionRef.doc(claimId) as firebase.firestore.DocumentReference<Claim>;

// NOTE: left commneted code as we will use it during working on ICB-10
const ORDER_BY_FILTER_MAP: Record<QueryFilterType, keyof Claim> = {
  [QueryFilterType.Recent]: 'createdAt',
  // [QueryFilterType.Trending]: 'modifiedAt', // change to totalCountDailyDifference
  [QueryFilterType.Popular]: 'totalCount',
  [QueryFilterType.MostTruths]: 'bsRate',
  [QueryFilterType.MostBs]: 'bsRate',
  [QueryFilterType.CloseCall]: 'bsRate',
};

const addWhereRange =
  (isAdmin: boolean, authorId?: string) =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) => {
    let newCollectionOrQuery = collectionOrQuery;
    if (!isAdmin) {
      newCollectionOrQuery = newCollectionOrQuery.where('isVisible', '==', true);
    }
    if (authorId) {
      newCollectionOrQuery = newCollectionOrQuery.where('author.id', '==', authorId);
    }

    return newCollectionOrQuery;
  };

const removePromotedClaims =
  () =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) =>
    collectionOrQuery.where('isPromoted', '==', false);

const addOrdering =
  (queryFilter: QueryFilterType) =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) => {
    const order = queryFilter === 'mostBs' ? 'asc' : 'desc';
    if (queryFilter === 'closeCall') {
      return queryFilter
        ? collectionOrQuery
            .where('bsRate', '>', -3)
            .where('bsRate', '<', 3)
            .orderBy(ORDER_BY_FILTER_MAP[queryFilter], order)
            .orderBy('modifiedAt', order)
        : collectionOrQuery;
    }
    return queryFilter ? collectionOrQuery.orderBy(ORDER_BY_FILTER_MAP[queryFilter], order) : collectionOrQuery;
  };

const addPagination =
  (queryFilter?: any, queryCursor?: Claim) =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) => {
    return queryCursor
      ? collectionOrQuery.startAfter(queryCursor[ORDER_BY_FILTER_MAP[queryFilter]])
      : collectionOrQuery;
  };

const addLimit =
  (limit: number) =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) => {
    return limit ? collectionOrQuery.limit(limit) : collectionOrQuery;
  };

const addCategoryFiltering =
  (queryCategory: ClaimsCategory) =>
  <T>(collectionOrQuery: CollectionReference<T> | Query<T>) => {
    return queryCategory && queryCategory !== ClaimsCategory.All
      ? collectionOrQuery.where('category', '==', queryCategory)
      : collectionOrQuery;
  };

export const createClaimsQuery = (
  isAdmin: boolean,
  queryFilter: any,
  queryCursor?: Claim,
  queryCategory?: ClaimsCategory
) => {
  return compose<Query<Claim>>(
    addPagination(queryFilter, queryCursor),
    addOrdering(queryFilter),
    addLimit(10),
    addWhereRange(isAdmin),
    addCategoryFiltering(queryCategory),
    removePromotedClaims()
  )(claimsCollectionRef);
};

export const createAddedClaimsQuery = (isAdmin: boolean, authorId: string) => {
  return compose<Query<Claim>>(addWhereRange(isAdmin, authorId))(claimsCollectionRef);
};

export const createPromotedClaimsQuery = (isAdmin: boolean) => {
  return <Query<Claim>>addWhereRange(isAdmin)(promotedClaimsCollectionRef);
};
