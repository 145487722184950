import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer } from 'modules/auth/reducer';
import { claimsReducer } from 'modules/claims';
import { userReducer } from 'modules/user';
import { layoutReducer } from 'modules/layout';
import { sharedReducer } from 'modules/shared/reducer';
import { rssReducer } from 'modules/rss';

import type { History } from 'history';
import type { Action } from 'modules/store/action';

export const rootReducer = (history: History) => (state: any, action: Action) => {
  return combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    user: userReducer,
    claims: claimsReducer,
    layout: layoutReducer,
    shared: sharedReducer,
    rss: rssReducer,
  })(state, action);
};

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
