import { joiResolver } from '@hookform/resolvers/joi';
import { CardContainer } from 'components/Card';
import Joi from 'joi';
import { useAuthManagement } from 'modules/auth';
import { useSharedManagement } from 'modules/shared/hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { i18nClient } from 'services/translations';
import { Button, Input } from 'shared';
import '../UserSettings.scss';

const deleteAccountFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
});

interface DeleteAccountFormData {
  email: string;
}

export const DeleteAccountContainer: React.FC = () => {
  const { errors, handleSubmit, control } = useForm<DeleteAccountFormData>({
    mode: 'onTouched',
    resolver: joiResolver(deleteAccountFormSchema),
  });
  const { t } = useTranslation();
  const { user, deleteUser } = useAuthManagement();
  const { notifyUser } = useSharedManagement();

  const isEmailError = Boolean(errors.email);

  const deleteUserAfterValidation = (userMail: string) => {
    if (user!.email !== userMail) {
      notifyUser(t('customizeAccount.wrongMail'), 'error');
      return;
    }

    deleteUser();
  };

  return (
    <CardContainer key={'delete-account'}>
      <span className="settings-header__title">{t('customizeAccount.deleteAccountLabel')}</span>
      <p style={{ color: 'red' }}>{t('customizeAccount.deleteConfirm')}</p>
      <form noValidate onSubmit={handleSubmit(({ email }) => deleteUserAfterValidation(email))}>
        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="email"
              label={t('auth.emailLabel')}
              value={field.value}
              error={isEmailError && errors.email!.message}
            />
          )}
        />
        <div className="settings-footer">
          <Button type="submit" style="dark">
            {t('customizeAccount.deleteAccountButtonLabel')}
          </Button>
        </div>
      </form>
    </CardContainer>
  );
};
