import React, { useCallback, useRef, useState, useLayoutEffect } from 'react';
import Truth from './Truth/Truth';
import Tie from './Tie/Tie';
import Bullshit from './Bullshit/Bullshit';
import { VoteStatus } from 'modules/claims';
import './TileRate.scss';

interface TileRateProps {
  voteStatus: VoteStatus;
  votePercentage: number;
}

export const TileRate: React.FC<TileRateProps> = ({ voteStatus, votePercentage }) => {
  const [barWidth, setBarWidth] = useState(0);
  const tileRateRef = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (tileRateRef) {
      setBarWidth(tileRateRef.current.clientWidth);
    }
  }, []);

  const renderRateBar = useCallback(() => {
    if (voteStatus === VoteStatus.Truth) {
      return <Truth votePercentage={votePercentage} barWidth={barWidth} />;
    }

    if (!votePercentage || votePercentage === 0 || votePercentage === 50) {
      return <Tie votePercentage={50} barWidth={barWidth} />;
    }

    return <Bullshit votePercentage={votePercentage} barWidth={barWidth} />;
  }, [barWidth, votePercentage, voteStatus]);

  return (
    <div className="tile-rate" ref={tileRateRef}>
      {renderRateBar()}
    </div>
  );
};
