import React from 'react';

export const Icon = props => (
  <i
    {...{
      ...props,
      className: `${props.className} material-icons`,
    }}
  >
    {props.type}
  </i>
);
