import type firebase from 'firebase';
import { createAction } from 'services/store/create-action';
import type { Urls } from './types';

export enum RssActionType {
  FetchRssInfo = '[RSS]: Fetch RSS info',
  FetchRssInfoSuccess = '[RSS]: Fetch RSS info - success',
  FetchRssInfoFailure = '[RSS]: Fetch RSS info - failure',
}

export const RssAction = {
  fetchRssInfo: () => createAction(RssActionType.FetchRssInfo),
  fetchRssInfoSuccess: (urls: Urls[]) => createAction(RssActionType.FetchRssInfoSuccess, { urls }),
  fetchRssInfoFailure: (error: firebase.FirebaseError) => createAction(RssActionType.FetchRssInfoFailure, { error }),
};

export type RssActionCreator = typeof RssAction;
