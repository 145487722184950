import React, { LegacyRef } from 'react';
import { ListView } from './components/ListView/ListView';
import { TilesView } from './components/TilesView/TilesView';
import { Claim } from 'modules/claims';
import { useLayoutManagement } from '../../modules/layout/hooks';
import { ViewTypes } from '../../modules/layout';
import './Claims.scss';
import { Call } from 'modules/user/types';
import Masonry from 'react-masonry-component';
import { useMediaQuery } from 'react-responsive';

interface ClaimsContainerProps {
  claims: Claim[];
  userCalls: Call[];
  promotedClaims?: Claim[];
  reference?: LegacyRef<HTMLDivElement>;
}
export const ClaimsContainer: React.VFC<ClaimsContainerProps> = ({ claims, userCalls, promotedClaims, reference }) => {
  const { viewType } = useLayoutManagement();
  const isTilesAndPromoted = promotedClaims && promotedClaims.length > 0 && viewType === ViewTypes.tiles;
  const isListAndPromoted = promotedClaims && promotedClaims.length > 0 && viewType === ViewTypes.list;
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
  return (
    <>
      <>
        <Masonry
          className="tiles-view"
          elementType="ul"
          options={{ itemSelector: '.tile', gutter: 20, fitWidth: true }}
        >
          {isTilesAndPromoted && <TilesView claims={promotedClaims} userCalls={userCalls} />}
          {viewType === ViewTypes.tiles && <TilesView claims={claims} userCalls={userCalls} />}
          {isMobile && (
            <>
              {isTilesAndPromoted && <TilesView claims={promotedClaims} userCalls={userCalls} />}
              <TilesView claims={claims} userCalls={userCalls} />
            </>
          )}
        </Masonry>
      </>
      {!isMobile && (
        <>
          {isListAndPromoted && <ListView claims={promotedClaims} userCalls={userCalls} />}
          {viewType === ViewTypes.list && <ListView claims={claims} userCalls={userCalls} />}
        </>
      )}
      <div className="footer" ref={reference} />
    </>
  );
};
