import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Joi from 'joi';

import { Button, Icon, Input } from '../../../../shared';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { i18nClient } from 'services/translations';

import { useAuthManagement } from '../../../../modules/auth';
import { useLayoutManagement } from '../../../../modules/layout/hooks';

import logo from 'assets/icbs_logo.svg';
import './Login.scss';

const loginFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
  password: Joi.string()
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
    }),
}).required();

interface LoginFormData {
  email: string;
  password: string;
}

// TODO: add translation support
export const Login: React.VFC = () => {
  const { loginUser, user, isErrored, resetAuthErrors } = useAuthManagement();
  const { showModal, hideModal } = useLayoutManagement();
  const { errors, handleSubmit, control } = useForm<LoginFormData>({
    mode: 'onTouched',
    resolver: joiResolver(loginFormSchema),
  });

  const history = useHistory();

  const isEmailError = Boolean(errors.email);
  const isPasswordError = Boolean(errors.password);

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
      if (isErrored) {
        resetAuthErrors();
      }
    };
  }, [hideModal, isErrored, resetAuthErrors, showModal]);

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, user]);

  const handleExit = () => history.push('/');

  return (
    <>
      <div className="login">
        <div className="login__topbar">
          <img className="login__logo" alt="I call BS" src={logo} />
          <Icon className="login__icon-right" type="clear" onClick={handleExit} />
        </div>
        <h2 className="login__header">Login</h2>
        <div>
          <form onSubmit={handleSubmit(loginUser)}>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={field => (
                <Input
                  name={field.name}
                  onChange={field.onChange}
                  type="text"
                  label="Email"
                  value={field.value}
                  error={isEmailError && errors.email!.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={field => (
                <Input
                  name={field.name}
                  onChange={field.onChange}
                  type="password"
                  label="Password"
                  value={field.value}
                  error={isPasswordError && errors.password!.message}
                />
              )}
            />
            {isErrored && <div className="error">{i18nClient.t('general.failureMessage')}</div>}
            <Link className="login__lost-pass" to="/lost-password">
              Lost password
            </Link>
            <div className="login__buttons">
              <Button icon="done" type="submit" style="dark">
                Login
              </Button>
              <Button icon="person_add" link="/register" type="light">
                Create Account
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="login__backdrop" onClick={handleExit} />
    </>
  );
};
