import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Login, LostPass, Register } from 'features/Auth';
import { ClaimsContainer } from 'features/Claims';
import { useAuthManagement } from 'modules/auth/hooks';
import { Topbar } from 'shared';
import './FeedContainer.scss';
import { useLayoutManagement } from '../../modules/layout/hooks';
import { QueryFilterType, useClaimsManagement } from '../../modules/claims';
import { useUserManagement } from '../../modules/user';
import { CategoryFilters } from 'features/Claims/components/CategoryFilters/CategoryFilters';
import { FeedHeader } from 'features/Claims/components/FeedHeader/FeedHeader';
import { ViewSwitch } from 'features/Claims/components/ViewSwitch/ViewSwitch';
import { CircularProgress } from '@material-ui/core';
import { Sorting } from 'features/Claims/components/Sorting/Sorting';

export const FeedContainer: React.VFC = () => {
  const { user, logoutUser } = useAuthManagement();
  const {
    claims,
    fetchClaims,
    promotedClaims,
    fetchPromotedClaims,
    queryCursor,
    isLoading,
    useInfiniteScroll,
    category,
    isRefetching,
  } = useClaimsManagement();
  const { userCalls, userHiddenClaims, userReportedClaimsIds } = useUserManagement();
  const { isModalShown, viewType } = useLayoutManagement();
  const [querySorting, setQuerySorting] = useState<QueryFilterType>(QueryFilterType.Recent);
  useEffect(() => {
    fetchClaims(querySorting, queryCursor, category);
  }, [fetchClaims, viewType, isLoading, category, querySorting]);

  useEffect(() => {
    fetchPromotedClaims();
  }, [fetchPromotedClaims]);

  const [ref] = useInfiniteScroll(queryCursor);
  const handleSortingChange = async newFilter => {
    setQuerySorting(newFilter);
  };
  const claimsWithoutHidden = claims.filter(({ id }) => !userHiddenClaims.includes(id));
  const claimsWithoutHiddenAndReported = claimsWithoutHidden.filter(({ id }) => !userReportedClaimsIds.includes(id));
  return (
    <>
      <div className={`fullApp ${isModalShown ? 'blur' : ''}`}>
        <Topbar logout={logoutUser} user={user} />
        <div className="app_container">
          <div className="app_content">
            <div className="claims">
              <FeedHeader>
                <CategoryFilters />
                <Sorting handleSortingChange={handleSortingChange} />
                <ViewSwitch />
              </FeedHeader>

              {!isLoading || !isRefetching ? (
                <ClaimsContainer
                  claims={claimsWithoutHiddenAndReported.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)}
                  promotedClaims={promotedClaims}
                  userCalls={userCalls}
                  reference={ref}
                />
              ) : (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Route path={'/login'} component={Login} />
      <Route path={'/register'} component={Register} />
      <Route path={'/lost-password'} component={LostPass} />
    </>
  );
};
