import firebase from 'firebase/app';
import { from, of, Observable, forkJoin } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  mergeMap,
  defaultIfEmpty,
  concatMap,
  takeUntil,
} from 'rxjs/operators';
import {
  createUserDocumentRefRead,
  createUserDocumentRefWrite,
  createUserCallDocumentRef,
  createUserCallsCollectionRef,
  createReportedClaimDocumentRef,
} from './queries';
import { combineEpics, Epic } from 'redux-observable';
import { ofType, Action } from 'modules/store';
import { AuthActionType } from '../auth';
import { RootState } from 'services/store';
import { UserData, Call, CallVoteType } from './types';
import { filterNotLoggedInUser } from 'modules/auth/utils';
import { firebaseFirestore } from 'services/firebase';
import { UserAction, UserActionType } from './actions';
import {
  claimsCollectionRef,
  Claim,
  ClaimsAction,
  createClaimDocumentRef,
  createAddedClaimsQuery,
} from 'modules/claims';
import { filterEmptyDocument } from './utils';
import { SharedAction } from 'modules/shared/actions';
import { i18nClient } from 'services/translations';

const fetchUserDataEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.FetchUserData),
    filterNotLoggedInUser(state$),
    switchMap(user =>
      new Observable<firebase.firestore.DocumentSnapshot<UserData>>(subscriber => {
        createUserDocumentRefRead(user.uid).onSnapshot(subscriber);
      }).pipe(
        takeUntil(actions$.pipe(ofType(AuthActionType.LogoutUserSuccess))),
        filterEmptyDocument(),
        map(documentData => UserAction.fetchUserDataSuccess(documentData)),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.fetchUserDataFailure(error)
          )
        )
      )
    )
  );

const saveUserCallEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SaveUserCall),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
      const batch = firebaseFirestore.batch();
      // TODO: create a doc ref here and pass the same id for claim and call
      batch.set<Partial<Call>>(createUserCallDocumentRef(auth.user.data?.uid!), {
        claimId: payload.claimId,
        createdAt: firebase.firestore.Timestamp.now(),
        modifiedAt: firebase.firestore.Timestamp.now(),
        author: {
          id: auth.user.data?.uid!,
          avatar: auth.user.data?.photoURL || null,
          name: auth.user.data?.displayName || null,
        },
        voteType: payload.voteType,
      });
      batch.update(claimsCollectionRef.doc(payload.claimId), {
        [payload.voteType === CallVoteType.Truth ? 'truthCount' : 'bullshitCount']:
          firebase.firestore.FieldValue.increment(1),
        totalCount: firebase.firestore.FieldValue.increment(1),
        modifiedAt: firebase.firestore.Timestamp.now(),
        bsRate:
          payload.voteType === CallVoteType.Truth
            ? firebase.firestore.FieldValue.increment(1)
            : firebase.firestore.FieldValue.increment(-1),
      });

      return from(batch.commit()).pipe(
        map(() => UserAction.saveUserCallSuccess(payload.claimId)),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.saveUserCallFailure(error)
          )
        )
      );
    })
  );

const deleteUserCallEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.DeleteUserCall),
    withLatestFrom(state$),
    concatMap(([{ payload }, { auth }]) => {
      const batch = firebaseFirestore.batch();

      batch.delete(createUserCallDocumentRef(auth.user.data?.uid!, payload.callId));

      batch.update(claimsCollectionRef.doc(payload.claimId), {
        [payload.voteType === CallVoteType.Truth ? 'truthCount' : 'bullshitCount']:
          firebase.firestore.FieldValue.increment(-1),
        bsRate:
          payload.voteType === CallVoteType.Truth
            ? firebase.firestore.FieldValue.increment(-1)
            : firebase.firestore.FieldValue.increment(1),
        totalCount: firebase.firestore.FieldValue.increment(-1),
        modifiedAt: firebase.firestore.Timestamp.now(),
      });

      return from(batch.commit()).pipe(
        map(() => UserAction.deleteUserCallSuccess(payload.claimId)),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.deleteUserCallFailure(error)
          )
        )
      );
    })
  );

const swapUserCallEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SwapUserCall),
    withLatestFrom(state$),
    concatMap(([{ payload }, { auth }]) => {
      const batch = firebaseFirestore.batch();
      const isCurrentCallTruth = payload.voteType === CallVoteType.Truth;

      batch.update(createUserCallDocumentRef(auth.user.data?.uid!, payload.callId), {
        voteType: isCurrentCallTruth ? CallVoteType.Bullshit : CallVoteType.Truth,
        modifiedAt: firebase.firestore.Timestamp.now(),
      });

      batch.update(claimsCollectionRef.doc(payload.claim.id), {
        truthCount: isCurrentCallTruth
          ? firebase.firestore.FieldValue.increment(-1)
          : firebase.firestore.FieldValue.increment(1),
        bullshitCount: isCurrentCallTruth
          ? firebase.firestore.FieldValue.increment(1)
          : firebase.firestore.FieldValue.increment(-1),
        bsRate: isCurrentCallTruth
          ? firebase.firestore.FieldValue.increment(-2)
          : firebase.firestore.FieldValue.increment(2),
        modifiedAt: firebase.firestore.Timestamp.now(),
      });

      return from(batch.commit()).pipe(
        map(() => UserAction.swapUserCallSuccess(payload.claim.id)),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.swapUserCallFailure(error)
          )
        )
      );
    })
  );

const fetchUserVotedClaims: Epic<Action> = (actions$, state$) => {
  return actions$.pipe(
    ofType(UserActionType.FetchUserVotedClaims),
    filterNotLoggedInUser(state$),
    withLatestFrom(state$),
    switchMap(([, { auth }]) =>
      from(createUserCallsCollectionRef(auth.user.data?.uid!).get()).pipe(
        mergeMap(callsQuerySnapshot => {
          const claims = callsQuerySnapshot.docs.map(call => claimsCollectionRef.doc(call.data().claimId).get());
          return forkJoin(claims).pipe(
            map(claimsSnapshot =>
              claimsSnapshot.map(claim => ({
                ...claim.data()!,
                id: claim.id,
              }))
            ),
            map(claimsObjects => UserAction.fetchUserVotedClaimsSuccess(claimsObjects))
          );
        }),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.fetchUserVotedClaimsFailure(error)
          )
        )
      )
    )
  );
};
const fetchUserDataSuccessEpic: Epic<Action, Action, RootState> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchUserDataSuccess),
    map(() => UserAction.fetchUserStarredClaims())
  );

const fetchUsersStarredClaims: Epic<Action, Action, RootState> = (actions$, state$) => {
  return actions$.pipe(
    ofType(UserActionType.FetchUserStarredClaims),
    filterNotLoggedInUser(state$),
    withLatestFrom(state$),
    switchMap(([, { user }]) => {
      const starredClaims$ = user.userData.starredClaimsIds.map(claimId => {
        return from(claimsCollectionRef.doc(claimId).get()).pipe(
          map(documentSnapshot => ({
            ...documentSnapshot.data()!,
            id: documentSnapshot.id,
          }))
        );
      });
      return forkJoin(starredClaims$).pipe(
        defaultIfEmpty<Claim[]>([]),
        map(claimsObjects => UserAction.fetchUserStarredClaimsSuccess(claimsObjects)),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.fetchUserStarredClaimsFailure(error)
          )
        )
      );
    })
  );
};

const saveUserStarredClaimIdEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SaveUserStarredClaimId),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        createUserDocumentRefWrite(auth.user.data?.uid!).update({
          starredClaimsIds: firebase.firestore.FieldValue.arrayUnion(payload.starredClaimId),
        })
      ).pipe(
        map(() => UserAction.saveUserStarredClaimIdSuccess()),
        catchError(error => {
          return of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.saveUserStarredClaimIdFailure(error)
          );
        })
      )
    )
  );

const deleteUserStarredClaimIdEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.DeleteUserStarredClaimId),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        createUserDocumentRefWrite(auth.user.data?.uid!).update({
          starredClaimsIds: firebase.firestore.FieldValue.arrayRemove(payload.starredClaimId),
        })
      ).pipe(
        map(() => UserAction.deleteUserStarredClaimIdSuccess()),
        catchError(error => {
          return of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.deleteUserStarredClaimIdFailure(error)
          );
        })
      )
    )
  );

const saveUserHiddenClaimIdEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SaveUserHiddenClaimId),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        createUserDocumentRefWrite(auth.user.data?.uid!).update({
          hiddenClaimsIds: firebase.firestore.FieldValue.arrayUnion(payload.hiddenClaimId),
        })
      ).pipe(
        map(
          () => SharedAction.notifyUser(i18nClient.t('customClaimDialog.claimHiddenNotify'), 'success'),
          UserAction.saveUserStarredClaimIdSuccess()
        ),
        catchError(error => {
          return of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.saveUserStarredClaimIdFailure(error)
          );
        })
      )
    )
  );

const fetchUserAddedClaims: Epic<Action> = (actions$, state$) => {
  return actions$.pipe(
    ofType(UserActionType.FetchUserVotedClaims),
    filterNotLoggedInUser(state$),
    withLatestFrom(state$),
    switchMap(([, { auth }]) =>
      from(createAddedClaimsQuery(auth.user.claims?.isAdmin, auth.user.data?.uid!).get()).pipe(
        map(callsQuerySnapshot => {
          const claims = callsQuerySnapshot.docs.map(querySnapshot => ({
            ...querySnapshot.data(),
            id: querySnapshot.id,
          }));

          return UserAction.fetchUserAddedClaimsSuccess(claims);
        }),
        catchError(error => of(UserAction.fetchUserAddedClaimsFailure(error)))
      )
    )
  );
};
const fetchUserCallsEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.FetchUserCalls),
    withLatestFrom(state$),
    switchMap(([, { auth }]) =>
      new Observable<firebase.firestore.QuerySnapshot<Call>>(subscriber => {
        createUserCallsCollectionRef(auth.user.data?.uid!).onSnapshot(subscriber);
      }).pipe(
        takeUntil(actions$.pipe(ofType(AuthActionType.LogoutUserSuccess))),
        map(querySnapshot => {
          const calls = querySnapshot.docs.map(queryDocumentSnapshot => ({
            ...queryDocumentSnapshot.data(),
            id: queryDocumentSnapshot.id,
          }));

          return UserAction.fetchUserCallsSuccess(calls);
        }),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.fetchUserCallsFailure(error)
          )
        )
      )
    )
  );

const refetchClaimEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(
      UserActionType.SaveUserCallSuccess,
      UserActionType.DeleteUserCallSuccess,
      UserActionType.SwapUserCallSuccess
    ),
    mergeMap(({ payload }) => [
      ClaimsAction.fetchClaim(payload.claimId),
      UserAction.fetchUserRelatedClaim(payload.claimId),
    ])
  );

const fetchUserRelatedClaimEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchUserRelatedClaim),
    switchMap(({ payload }) => {
      return from(createClaimDocumentRef(payload.claimId).get()).pipe(
        map(documentSnapshot => {
          const claim = {
            ...documentSnapshot.data()!,
            id: documentSnapshot.id,
          };
          return UserAction.fetchUserRelatedClaimSuccess(claim);
        }),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.fetchUserRelatedClaimFailure(error)
          )
        )
      );
    })
  );

const saveUserPersonalInfoEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SaveUserPersonalInfo),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        createUserDocumentRefWrite(auth.user.data?.uid!).update({
          userPersonalInfo: payload,
        })
      ).pipe(
        mergeMap(() =>
          of(
            SharedAction.notifyUser(i18nClient.t('customizeAccount.personalInfoSuccessMessage')),
            UserAction.saveUserPersonalInfoSuccess()
          )
        ),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
            UserAction.saveUserPersonalInfoFailure(error)
          )
        )
      )
    )
  );

const saveReportedClaimEpic: Epic<Action, Action> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.SaveReportedClaim),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
      const batch = firebaseFirestore.batch();
      const ref = createReportedClaimDocumentRef(payload.reportedClaimId);

      return from(ref.get())
        .pipe(
          map(querySnapshot => {
            if (querySnapshot.exists) {
              batch.update(createReportedClaimDocumentRef(payload.reportedClaimId), {
                reportedClaims: firebase.firestore.FieldValue.arrayUnion({
                  claimId: payload.reportedClaimId,
                  userId: auth.user.data?.uid!,
                  reason: payload.reason,
                  createdAt: firebase.firestore.Timestamp.now(),
                }),
              });
            } else {
              batch.set(createReportedClaimDocumentRef(payload.reportedClaimId), {
                reportedClaims: {
                  claimId: payload.reportedClaimId,
                  userId: auth.user.data?.uid!,
                  reason: payload.reason,
                  createdAt: firebase.firestore.Timestamp.now(),
                },
              });
            }
            batch.update(createUserDocumentRefWrite(auth.user.data?.uid!), {
              reportedClaims: firebase.firestore.FieldValue.arrayUnion({
                claimId: payload.reportedClaimId,
                reason: payload.reason,
                createdAt: firebase.firestore.Timestamp.now(),
              }),
            });
            batch.commit();
          })
        )
        .pipe(
          mergeMap(() => {
            return of(
              SharedAction.notifyUser(i18nClient.t('customClaimDialog.claimRportedNotify'), 'success'),
              UserAction.saveReportedClaimSuccess()
            );
          }),
          catchError(error =>
            of(
              SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'),
              UserAction.saveReportedClaimFailure(error)
            )
          )
        );
    })
  );
export const userEpics = combineEpics(
  fetchUserDataEpic,
  fetchUserVotedClaims,
  fetchUserDataSuccessEpic,
  fetchUsersStarredClaims,
  saveUserStarredClaimIdEpic,
  deleteUserStarredClaimIdEpic,
  fetchUserCallsEpic,
  saveUserCallEpic,
  deleteUserCallEpic,
  swapUserCallEpic,
  refetchClaimEpic,
  fetchUserRelatedClaimEpic,
  saveUserPersonalInfoEpic,
  saveReportedClaimEpic,
  saveUserHiddenClaimIdEpic,
  fetchUserAddedClaims
);
