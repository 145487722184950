import { ClaimsCategory } from 'features/Claims/consts';
import type firebase from 'firebase';
import { createAction } from 'services/store/create-action';
import type { Claim, QueryFilterType } from './types';

export enum ClaimsActionType {
  FetchClaim = '[Claims]: Fetch claim',
  FetchClaimSuccess = '[Claims]: Fetch claim - success',
  FetchClaimFailure = '[Claims]: Fetch claim - failure',

  DeleteClaim = '[Claims]: Delete claim',
  DeleteClaimSuccess = '[Claims]: Delete claim - success',
  DeleteClaimFailure = '[Claims]: Delete claim - failure',

  ToggleClaimVisibility = '[Claims]: Toggle claim visibility',
  ToggleClaimVisibilitySuccess = '[Claims]: Toggle claim visibility - success',
  ToggleClaimVisibilityFailure = '[Claims]: Toggle claim visibility - failure',

  FetchClaims = '[Claims]: Fetch claims',
  FetchClaimsSuccess = '[Claims]: Fetch claims - success',
  FetchClaimsFailure = '[Claims]: Fetch claims - failure',

  ToggleClaimPromotion = '[Claims]: Toggle promoted claim',
  ToggleClaimPromotionSuccess = '[Claims]: Toggle promoted claim - success',
  ToggleClaimPromotionFailure = '[Claims]: Toggle promoted claim - failure',

  FetchPromotedClaims = '[Claims]: Fetch promoted claims',
  FetchPromotedClaimsSuccess = '[Claims]: Fetch promoted claims - success',
  FetchPromotedClaimsFailure = '[Claims]: Fetch promoted claims - failure',

  SaveCustomClaim = '[Browser]: Save custom claim',
  SaveCustomClaimSuccess = '[Browser]: Save custom claim - success',
  SaveCustomClaimFailure = '[Browser]: Save custom claim - failure',

  ChangeClaimsCategory = '[Claims]: Change claims category',
}

export const ClaimsAction = {
  fetchClaim: (claimId: string) => createAction(ClaimsActionType.FetchClaim, { claimId }),
  fetchClaimSuccess: (claim: Claim) => createAction(ClaimsActionType.FetchClaimSuccess, { claim }),
  fetchClaimFailure: (error: firebase.FirebaseError) => createAction(ClaimsActionType.FetchClaimFailure, { error }),

  deleteClaim: (claimId: string) => createAction(ClaimsActionType.DeleteClaim, { claimId }),
  deleteClaimSuccess: () => createAction(ClaimsActionType.DeleteClaimSuccess),
  deleteClaimFailure: (error: firebase.FirebaseError) => createAction(ClaimsActionType.DeleteClaimFailure, { error }),

  toggleClaimVisibility: (claimId: string, isVisible: boolean) =>
    createAction(ClaimsActionType.ToggleClaimVisibility, { claimId, isVisible }),
  toggleClaimVisibilitySuccess: (claimId: string) =>
    createAction(ClaimsActionType.ToggleClaimVisibilitySuccess, { claimId }),
  toggleClaimVisibilityFailure: (error: firebase.FirebaseError) =>
    createAction(ClaimsActionType.ToggleClaimVisibilityFailure, { error }),

  fetchClaims: (queryFilter: QueryFilterType, queryCursor?: Claim, queryCategory?: ClaimsCategory) =>
    createAction(ClaimsActionType.FetchClaims, { queryFilter, queryCursor, queryCategory }),
  fetchClaimsSuccess: (
    claims: Claim[],
    nextQueryCursor: Claim | undefined,
    shouldUseQueryCursor: boolean,
    hasNextPage: boolean,
    currentFilter: string
  ) =>
    createAction(ClaimsActionType.FetchClaimsSuccess, {
      claims,
      nextQueryCursor,
      shouldUseQueryCursor,
      hasNextPage,
      currentFilter,
    }),
  fetchClaimsFailure: (error: firebase.FirebaseError) => createAction(ClaimsActionType.FetchClaimsFailure, { error }),

  toggleClaimPromotion: (claimId: string, isPromoted: boolean) =>
    createAction(ClaimsActionType.ToggleClaimPromotion, { claimId, isPromoted }),
  toggleClaimPromotionSuccess: (claimId: string) =>
    createAction(ClaimsActionType.ToggleClaimPromotionSuccess, { claimId }),
  toggleClaimPromotionFailure: (error: firebase.FirebaseError) =>
    createAction(ClaimsActionType.ToggleClaimPromotionFailure, { error }),

  fetchPromotedClaims: () => createAction(ClaimsActionType.FetchPromotedClaims),
  fetchPromotedClaimsSuccess: (promotedClaims: Claim[]) =>
    createAction(ClaimsActionType.FetchPromotedClaimsSuccess, { promotedClaims }),
  fetchPromotedClaimsFailure: (error: firebase.FirebaseError) =>
    createAction(ClaimsActionType.FetchPromotedClaimsFailure, { error }),

  saveCustomClaim: (
    claimText: string,
    claimedBy: string,
    claimUrl?: string,
    category?: string,
    queryFilter?: QueryFilterType,
    queryCursor?: Claim,
    queryCategory?: ClaimsCategory
  ) =>
    createAction(ClaimsActionType.SaveCustomClaim, {
      claimText,
      claimUrl,
      claimedBy,
      category,
      queryFilter,
      queryCursor,
      queryCategory,
    }),
  saveCustomClaimSuccess: () => createAction(ClaimsActionType.SaveCustomClaimSuccess),
  saveCustomClaimFailure: (error: firebase.FirebaseError) =>
    createAction(ClaimsActionType.SaveCustomClaimFailure, { error }),

  changeClaimsCategory: (claimsCategory: ClaimsCategory) =>
    createAction(ClaimsActionType.ChangeClaimsCategory, { claimsCategory }),
};

export type ClaimsActionCreator = typeof ClaimsAction;
