import React from 'react';
import { createPolygonPoints } from 'modules/claims/utils';
import { VoteStatus } from 'modules/claims';
import './Truth.scss';

type TruthProps = {
  votePercentage: number;
  barWidth: number;
};
const Truth: React.VFC<TruthProps> = ({ votePercentage, barWidth }) => {
  const polygons = createPolygonPoints(barWidth, VoteStatus.Truth, votePercentage);

  return (
    <>
      <span className="tile-rate__result tile-rate__result--truth">
        {votePercentage}
        <span className="tile-rate__percent">%</span>
      </span>
      <svg className="tile-rate__progress-bar" height="40" width={barWidth}>
        <polygon className="tile-rate__bar--light-green" points={polygons.right} />
        <polygon className="tile-rate__bar--green" points={polygons.left} />
      </svg>
    </>
  );
};

export default Truth;
