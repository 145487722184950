import React from 'react';
import './AboutCard.scss';

type AboutCardProps = {
  title: string;
  paragraphs: string[];
};
export const AboutCard: React.FC<AboutCardProps> = ({ title, paragraphs }) => {
  return (
    <div className="about-card-wrapper">
      <div className="about-info-section">
        <div className="about-header">
          <h3>{title}</h3>
        </div>
        {paragraphs.map(paragraph => {
          return (
            <div className="about-paragraph" key={paragraph}>
              {paragraph}
            </div>
          );
        })}
      </div>
    </div>
  );
};
