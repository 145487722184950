import React, { useState } from 'react';
import { useAuthManagement } from 'modules/auth';
import { determineClaimVoteStatus, Claim } from 'modules/claims';
import { TileClaim, TileRate, TileAdminControls } from '../../Tile';
import './ListViewItem.scss';
import { Call } from '../../../../../modules/user';
import { ListTitleResult } from '../ListTitleResult/ListTitleResult';
import { ListVote } from '../ListVote/ListVote';
import { ListVotedPanel } from '../ListVotePanel/ListVotePanel';
import { Source } from 'shared';

interface ListViewItemProps {
  claim: Claim;
  userCall: Call;
}

export const ListViewItem: React.VFC<ListViewItemProps> = ({ claim, userCall }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { isAdmin } = useAuthManagement();
  const { voteStatus, votePercentage } = determineClaimVoteStatus(claim);

  return (
    <div className="tile-list" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      {isAdmin && <TileAdminControls isVisible={isHovering} claim={claim} />}
      <div className="tile-list__padding">
        <TileRate voteStatus={voteStatus} votePercentage={votePercentage} />
        <ListTitleResult
          voteStatus={voteStatus}
          voteTotalCount={claim.totalCount}
          claimId={claim.id}
          claimCreatedAt={claim.createdAt}
          claimedBy={claim.claimedBy}
          claimCategory={claim.category}
        />
        <TileClaim text={claim.sentenceText} />
        {claim.sourceUrl !== null && <Source source={claim.sourceUrl} />}
      </div>
      <div className="title-list__voting">
        {userCall ? (
          <ListVotedPanel
            claim={claim}
            userCallId={userCall.id}
            userCallType={userCall.voteType}
            userCallCreatedAt={userCall.createdAt}
          />
        ) : (
          <ListVote claim={claim} />
        )}
      </div>
    </div>
  );
};
