import React from 'react';
import { MdRecordVoiceOver } from 'react-icons/md';
import { useHistory } from 'react-router';
import { useUserManagement, CallVoteType } from 'modules/user';
import BSIcon from '../BSIcon/BSIcon';
import TruthIcon from '../TruthIcon/TruthIcon';
import './TileVote.scss';
import { useAuthManagement } from 'modules/auth/hooks';
import { useTranslation } from 'react-i18next';
import { Claim } from 'modules/claims';

interface TileVoteProps {
  claim: Claim;
}

export const TileVote: React.FC<TileVoteProps> = ({ claim }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { saveUserCall } = useUserManagement();
  const { isLoggedIn } = useAuthManagement();

  const handleClaimVote = (voteType: CallVoteType) => (): void => {
    if (isLoggedIn) {
      saveUserCall(claim.id, voteType);
    } else {
      history.push('/login');
    }
  };

  return (
    <div className="tile-vote">
      <MdRecordVoiceOver className="tile-vote__icon" />
      <span className="tile-vote__title">You call it!</span>
      <div className="tile-vote__button-container">
        <button className="tile-vote__button" onClick={handleClaimVote(CallVoteType.Truth)}>
          <TruthIcon />
          <span>{t('tile.truthLabel')}</span>
        </button>
        <button className="tile-vote__button" onClick={handleClaimVote(CallVoteType.Bullshit)}>
          <BSIcon />
          <span>{t('tile.bsLabel')}</span>
        </button>
      </div>
    </div>
  );
};
