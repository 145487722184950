import React, { useEffect } from 'react';
import './SettingsContainer.scss';
import Masonry from 'react-masonry-component';
import { Topbar } from 'shared';
import { useAuthManagement } from 'modules/auth';
import { PersonalInformationContainer } from 'features/UserSettings';
import { useUserManagement } from 'modules/user';
import { ChangePasswordContainer } from 'features/UserSettings/ChangePassword';
import { DeleteAccountContainer } from 'features/UserSettings/DeleteAccount';

export const SettingsContainer: React.FC = () => {
  const { user, logoutUser } = useAuthManagement();
  const { fetchUserData, isUserDataLoaded } = useUserManagement();

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      <Topbar logout={logoutUser} user={user} />
      <div className="settings">
        {isUserDataLoaded && (
          <Masonry
            className="settings-cards-view"
            elementType="ul"
            options={{ itemSelector: '.card', gutter: 20, fitWidth: true }}
          >
            <PersonalInformationContainer />
            <ChangePasswordContainer />
            <DeleteAccountContainer />
          </Masonry>
        )}
      </div>
    </>
  );
};
