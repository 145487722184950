import React from 'react';
import { MdDelete, MdSwapHoriz } from 'react-icons/md';
import { ButtonTypes } from './types';

interface EditRemoveCallButtonProps {
  buttonType: ButtonTypes;
  onClick: () => void;
}

export const EditRemoveCallButton: React.VFC<EditRemoveCallButtonProps> = ({ buttonType, onClick }) => {
  return (
    <div className="tile-voted-panel__icon" onClick={onClick}>
      {buttonType === ButtonTypes.remove && <MdDelete />}
      {buttonType === ButtonTypes.swap && <MdSwapHoriz />}
    </div>
  );
};
