import React from 'react';
import type firebase from 'firebase/app';
import { format } from 'date-fns';
import { CallVoteType, useUserManagement } from 'modules/user';
import { Claim } from 'modules/claims';
import { useTranslation } from 'react-i18next';
import { MdCircle, MdRecordVoiceOver } from 'react-icons/md';
import { EditRemoveCallButton } from '../../Tile/TileVotedPanel/EditRemoveCallButton';
import { ButtonTypes } from '../../Tile/TileVotedPanel/types';
import './ListVotePanel.scss';
import TruthIcon from '../../Tile/TruthIcon/TruthIcon';
import BSIcon from '../../Tile/BSIcon/BSIcon';

interface ListVotedPanelProps {
  claim: Claim;
  userCallId: string;
  userCallType: CallVoteType;
  userCallCreatedAt: firebase.firestore.Timestamp;
}

export const ListVotedPanel: React.VFC<ListVotedPanelProps> = ({
  claim,
  userCallId,
  userCallType,
  userCallCreatedAt,
}) => {
  const { deleteUserCall, swapUserCall } = useUserManagement();
  const { t } = useTranslation();

  const hasVotedTruth = userCallType === CallVoteType.Truth;

  const handleOnPressDelete = (): void => {
    deleteUserCall(claim.id, userCallId, userCallType);
  };

  const handleOnPressSwap = (): void => {
    swapUserCall(claim, userCallId, userCallType);
  };
  return (
    <div className="list-tile-voted-panel-container">
      <div className="list-tile-voted-panel__header">
        <MdRecordVoiceOver className="tile-voted-panel__header-icon" />
        <span className="list-tile-vote__title">{t('tile.calledLabel')}</span>
        {hasVotedTruth && (
          <div className="list-title-voted-panel__result">
            <TruthIcon />
            <span className="list-title-voted-panel__result-text green">{t('tile.truthLabel')}</span>
          </div>
        )}
        {!hasVotedTruth && (
          <div className="list-title-voted-panel__result">
            <BSIcon />
            <span className="list-title-voted-panel__result-text red">{t('tile.bsLabel')}</span>
          </div>
        )}
        <div className="list-title-voted-panel__time">
          <MdCircle className="list-title-voted-panel__time__icon" />
          <span className="list-title-voted-panel__time__text">
            {format(userCallCreatedAt.toDate(), 'dd LLL yyyy')}
          </span>
        </div>
      </div>
      <div className="list-tile-voted-panel__buttons-wrapper">
        <EditRemoveCallButton buttonType={ButtonTypes.remove} onClick={handleOnPressDelete} />
        <EditRemoveCallButton buttonType={ButtonTypes.swap} onClick={handleOnPressSwap} />
      </div>
    </div>
  );
};
