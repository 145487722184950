import React from 'react';
import { Claim } from 'modules/claims';
import { ListViewItem } from './ListViewItem/ListViewItem';
import { Call } from '../../../../modules/user';

interface ListViewProps {
  claims: Claim[];
  userCalls: Call[];
}

export const ListView: React.FC<ListViewProps> = ({ claims, userCalls }) => {
  return (
    <ul>
      {claims.length &&
        claims.map(claim => {
          return (
            <ListViewItem key={claim.id} claim={claim} userCall={userCalls.find(call => call.claimId === claim.id)} />
          );
        })}
    </ul>
  );
};
