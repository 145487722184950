import type firebase from 'firebase';
import { createAction } from 'services/store';
import { Claim } from 'modules/claims';

import type { UserData, Call, CallVoteType, PersonalInfoType } from './types';

export enum UserActionType {
  FetchUserData = '[User]: Fetch user data',
  FetchUserDataSuccess = '[User]: Fetch user data - success',
  FetchUserDataFailure = '[User]: Fetch user data - failure',

  FetchUserVotedClaims = '[User]: Fetch user voted claims',
  FetchUserVotedClaimsSuccess = '[User]: Fetch user voted claims - success',
  FetchUserVotedClaimsFailure = '[User]: Fetch user voted claims - failure',

  FetchUserStarredClaims = '[User]: Fetch user starred claims',
  FetchUserStarredClaimsSuccess = '[User]: Fetch user starred claims - success',
  FetchUserStarredClaimsFailure = '[User]: Fetch user starred claims - failure',

  SaveUserStarredClaimId = '[User]: Save user starred claim id',
  SaveUserStarredClaimIdSuccess = '[User]: Save user starred claim id - success',
  SaveUserStarredClaimIdFailure = '[User]: Save user starred claim id - failure',

  DeleteUserStarredClaimId = '[User]: Delete user starred claim id',
  DeleteUserStarredClaimIdSuccess = '[User]: Delete user starred claim id - success',
  DeleteUserStarredClaimIdFailure = '[User]: Delete user starred claim id - failure',

  SaveUserHiddenClaimId = '[User]: Save user hidden claim id',
  SaveUserHiddenClaimIdSuccess = '[User]: Save user hidden claim id - success',
  SaveUserHiddenClaimIdFailure = '[User]: Save user hidden claim id - failure',

  FetchUserCalls = '[User]: Fetch user calls',
  FetchUserCallsSuccess = '[User]: Fetch user calls - success',
  FetchUserCallsFailure = '[User]: Fetch user calls - failure',

  SaveUserCall = '[User]: Save user call',
  SaveUserCallSuccess = '[User]: Save user call - success',
  SaveUserCallFailure = '[User]: Save user call - failure',

  DeleteUserCall = '[User]: Delete user call',
  DeleteUserCallSuccess = '[User]: Delete user call - success',
  DeleteUserCallFailure = '[User]: Delete user call - failure',

  SwapUserCall = '[User]: Swap user call',
  SwapUserCallSuccess = '[User]: Swap user call - success',
  SwapUserCallFailure = '[User]: Swap user call - failure',

  FetchUserRelatedClaim = '[User]: Fetch user related claim',
  FetchUserRelatedClaimSuccess = '[User]: Fetch user related claim - success',
  FetchUserRelatedClaimFailure = '[User]: Fetch user related claim - failure',

  SaveUserPersonalInfo = '[User] Save user personal info',
  SaveUserPersonalInfoSuccess = '[User]: Save user personal info - success',
  SaveUserPersonalInfoFailure = '[User]: Save user personal info - failure',

  SaveReportedClaim = '[User] Save reported claim',
  SaveReportedClaimSuccess = '[User]: Save reported claim - success',
  SaveReportedClaimFailure = '[User]: Save reported claim - failure',

  FetchUserAddedClaims = '[User]: Fetch user added claims',
  FetchUserAddedClaimsSuccess = '[User]: Fetch user added claims - success',
  FetchUserAddedClaimsFailure = '[User]: Fetch user added claims - failure',
}

export const UserAction = {
  fetchUserData: () => createAction(UserActionType.FetchUserData),
  fetchUserDataSuccess: (userData: UserData) => createAction(UserActionType.FetchUserDataSuccess, { userData }),
  fetchUserDataFailure: (error: firebase.FirebaseError) => createAction(UserActionType.FetchUserDataFailure, { error }),

  fetchUserVotedClaims: () => createAction(UserActionType.FetchUserVotedClaims),
  fetchUserVotedClaimsSuccess: (userVotedClaims: Claim[]) =>
    createAction(UserActionType.FetchUserVotedClaimsSuccess, { userVotedClaims }),
  fetchUserVotedClaimsFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.FetchUserVotedClaimsFailure, { error }),

  fetchUserStarredClaims: () => createAction(UserActionType.FetchUserStarredClaims),
  fetchUserStarredClaimsSuccess: (userStarredClaims: Claim[]) =>
    createAction(UserActionType.FetchUserStarredClaimsSuccess, { userStarredClaims }),
  fetchUserStarredClaimsFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.FetchUserStarredClaimsFailure, { error }),

  saveUserStarredClaimId: (starredClaimId: string) =>
    createAction(UserActionType.SaveUserStarredClaimId, { starredClaimId }),
  saveUserStarredClaimIdSuccess: () => createAction(UserActionType.SaveUserStarredClaimIdSuccess),
  saveUserStarredClaimIdFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.SaveUserStarredClaimIdFailure, { error }),

  deleteUserStarredClaimId: (starredClaimId: string) =>
    createAction(UserActionType.DeleteUserStarredClaimId, { starredClaimId }),
  deleteUserStarredClaimIdSuccess: () => createAction(UserActionType.DeleteUserStarredClaimIdSuccess),
  deleteUserStarredClaimIdFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.DeleteUserStarredClaimIdFailure, { error }),

  saveUserHiddenClaimId: (hiddenClaimId: string) =>
    createAction(UserActionType.SaveUserHiddenClaimId, { hiddenClaimId }),
  saveUserHiddenClaimIdSuccess: () => createAction(UserActionType.SaveUserHiddenClaimIdSuccess),
  saveUserHiddenClaimIdFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.SaveUserHiddenClaimIdFailure, { error }),

  saveReportedClaim: (reportedClaimId: string, reason: string) =>
    createAction(UserActionType.SaveReportedClaim, { reportedClaimId, reason }),
  saveReportedClaimSuccess: () => createAction(UserActionType.SaveReportedClaimSuccess),
  saveReportedClaimFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.SaveReportedClaimFailure, { error }),

  fetchUserCalls: () => createAction(UserActionType.FetchUserCalls),
  fetchUserCallsSuccess: (calls: Call[]) => createAction(UserActionType.FetchUserCallsSuccess, { calls }),
  fetchUserCallsFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.FetchUserCallsFailure, { error }),

  saveUserCall: (claimId: string, voteType: CallVoteType) =>
    createAction(UserActionType.SaveUserCall, { claimId, voteType }),
  saveUserCallSuccess: (claimId: string) => createAction(UserActionType.SaveUserCallSuccess, { claimId }),
  saveUserCallFailure: (error: firebase.FirebaseError) => createAction(UserActionType.SaveUserCallFailure, { error }),

  deleteUserCall: (claimId: string, callId: string, voteType: CallVoteType) =>
    createAction(UserActionType.DeleteUserCall, { claimId, callId, voteType }),
  deleteUserCallSuccess: (claimId: string) => createAction(UserActionType.DeleteUserCallSuccess, { claimId }),
  deleteUserCallFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.DeleteUserCallFailure, { error }),

  swapUserCall: (claim: Claim, callId: string, voteType: CallVoteType) =>
    createAction(UserActionType.SwapUserCall, { claim, callId, voteType }),
  swapUserCallSuccess: (claimId: string) => createAction(UserActionType.SwapUserCallSuccess, { claimId }),
  swapUserCallFailure: (error: firebase.FirebaseError) => createAction(UserActionType.SwapUserCallFailure, { error }),

  fetchUserRelatedClaim: (claimId: string) => createAction(UserActionType.FetchUserRelatedClaim, { claimId }),
  fetchUserRelatedClaimSuccess: (claim: Claim) => createAction(UserActionType.FetchUserRelatedClaimSuccess, { claim }),
  fetchUserRelatedClaimFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.FetchUserRelatedClaimFailure, { error }),

  saveUserPersonalInfo: ({ name, location, age }: PersonalInfoType) =>
    createAction(UserActionType.SaveUserPersonalInfo, { name, age, location }),
  saveUserPersonalInfoSuccess: () => createAction(UserActionType.SaveUserPersonalInfoSuccess),
  saveUserPersonalInfoFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.SaveUserPersonalInfoFailure, { error }),

  fetchUserAddedClaims: () => createAction(UserActionType.FetchUserAddedClaims),
  fetchUserAddedClaimsSuccess: (userAddedClaims: Claim[]) =>
    createAction(UserActionType.FetchUserAddedClaimsSuccess, { userAddedClaims }),
  fetchUserAddedClaimsFailure: (error: firebase.FirebaseError) =>
    createAction(UserActionType.FetchUserAddedClaimsFailure, { error }),
};

export type UserActionCreator = typeof UserAction;
