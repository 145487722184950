import { of, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'modules/store/action';
import { ofType } from 'modules/store/of-type';
import { RssAction, RssActionType } from './actions';
import { rssInfoQuery } from './queries';

const fetchRssInfoEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(RssActionType.FetchRssInfo),
    switchMap(() =>
      from(rssInfoQuery.get()).pipe(
        map(querySnapshot => {
          const urls = querySnapshot.docs.map(doc => doc.data());
          return RssAction.fetchRssInfoSuccess(urls);
        }),
        catchError(error => of(RssAction.fetchRssInfoFailure(error)))
      )
    )
  );

export const rssEpics = combineEpics(fetchRssInfoEpic);
