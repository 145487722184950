import { QueryFilterType } from 'modules/claims';
import { ClaimsCategory, SortingTypes } from 'features/Claims/consts';
import type { Action } from 'modules/store/action';
import { Status, isStatusSuccess } from 'modules/store/status';
import { ClaimsActionType } from './actions';
import type { ClaimsState } from './state';

export const claimsState: ClaimsState = {
  claimsList: {
    data: [],
    queryCursor: undefined,
    status: Status.DEFAULT,
    category: ClaimsCategory.All,
    shouldUseQueryCursor: false,
    hasNextPage: true,
    lastFilter: SortingTypes[0].key,
  },
  promotedClaimsList: {
    data: [],
    status: Status.DEFAULT,
  },
};

export const claimsReducer = (state = claimsState, action: Action): ClaimsState => {
  switch (action.type) {
    /**
     * FetchClaim
     */
    case ClaimsActionType.FetchClaim:
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          status: isStatusSuccess(state.claimsList.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
      };

    case ClaimsActionType.FetchClaimSuccess: {
      const isPromoted = action.payload.claim.isPromoted;
      if (isPromoted) {
        const index = state.promotedClaimsList.data.findIndex(claim => claim.id === action.payload.claim.id);

        const doesIndexExist = index > -1;
        return {
          ...state,
          claimsList: {
            ...state.claimsList,
          },
          promotedClaimsList: {
            ...state.promotedClaimsList,
            data: doesIndexExist
              ? [
                  ...state.promotedClaimsList.data.slice(0, index),
                  action.payload.claim,
                  ...state.promotedClaimsList.data.slice(index + 1),
                ]
              : [...state.promotedClaimsList.data, action.payload.claim],
          },
        };
      }
      const index = state.claimsList.data.findIndex(claim => claim.id === action.payload.claim.id);
      const doesIndexExist = index > -1;

      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          data: doesIndexExist
            ? [
                ...state.claimsList.data.slice(0, index),
                action.payload.claim,
                ...state.claimsList.data.slice(index + 1),
              ]
            : [...state.claimsList.data, action.payload.claim],
        },
        promotedClaimsList: {
          ...state.promotedClaimsList,
        },
      };
    }

    case ClaimsActionType.FetchClaimFailure:
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
        },
      };

    /**
     * FetchClaims
     */
    case ClaimsActionType.FetchClaims:
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          status: isStatusSuccess(state.claimsList.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
      };

    case ClaimsActionType.FetchClaimsSuccess:
      const shouldReset = state.claimsList.lastFilter === action.payload.currentFilter ? false : true;
      shouldReset && (state.claimsList.data = []);
      shouldReset ? (action.payload.shouldUseQueryCursor = false) : (action.payload.shouldUseQueryCursor = true);
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          data: action.payload.shouldUseQueryCursor
            ? [...state.claimsList.data, ...action.payload.claims]
            : action.payload.claims,
          queryCursor: action.payload.nextQueryCursor,
          shouldUseQueryCursor: true,
          status: Status.SUCCESS,
          hasNextPage: action.payload.hasNextPage,
        },
      };
    case ClaimsActionType.FetchClaimsFailure:
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          status: Status.FAILURE,
        },
      };

    /**
     * FetchPromotedClaims
     */
    case ClaimsActionType.FetchPromotedClaims:
      return {
        ...state,
        promotedClaimsList: {
          ...state.promotedClaimsList,
          status: isStatusSuccess(state.promotedClaimsList.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
      };

    case ClaimsActionType.FetchPromotedClaimsSuccess:
      return {
        ...state,
        promotedClaimsList: {
          data: action.payload.promotedClaims,
          status: Status.SUCCESS,
        },
      };

    case ClaimsActionType.FetchPromotedClaimsFailure:
      return {
        ...state,
        promotedClaimsList: {
          ...state.promotedClaimsList,
          status: Status.FAILURE,
        },
      };

    case ClaimsActionType.ChangeClaimsCategory:
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          category: action.payload.claimsCategory,
          shouldUseQueryCursor: false,
          hasNextPage: true,
        },
      };

    default:
      return state;
  }
};
