import { RootState } from 'services/store/root-reducer';

export const selectUserData = (store: RootState): RootState['user']['userData'] => {
  return store.user.userData;
};

export const selectUserVotedClaims = (store: RootState): RootState['user']['userVotedClaims'] => {
  return store.user.userVotedClaims;
};

export const selectUserStarredClaims = (store: RootState): RootState['user']['userStarredClaims'] => {
  return store.user.userStarredClaims;
};

export const selectUserCalls = (store: RootState): RootState['user']['userCalls'] => {
  return store.user.userCalls;
};

export const selectDeleteUserCallStatus = (store: RootState): RootState['user']['deleteUserCallStatus'] => {
  return store.user.deleteUserCallStatus;
};

export const selectSwapUserCallStatus = (store: RootState): RootState['user']['swapUserCallStatus'] => {
  return store.user.swapUserCallStatus;
};
export const selectUserAddedClaims = (store: RootState): RootState['user']['userAddedClaims'] => {
  return store.user.userAddedClaims;
};
