import { of, from, forkJoin } from 'rxjs';
import firebase from 'firebase/app';
import { catchError, map, switchMap, withLatestFrom, filter, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'modules/store/action';
import { ofType } from 'modules/store/of-type';
import { createCallsCollectionGroup, createUsersWithStarredClaimsCollectionGroup } from 'modules/user';
import { SharedAction } from 'modules/shared';
import { RootState } from 'services/store';
import { firebaseFirestore } from 'services/firebase';
import { i18nClient } from 'services/translations';
import { ClaimsAction, ClaimsActionType } from './actions';
import {
  createClaimDocumentRef,
  createClaimsQuery,
  createPromotedClaimsQuery,
  customClaimCollectionRef,
} from './queries';
import { QueryFilterType } from './types';

const fetchClaimEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ClaimsActionType.FetchClaim),
    switchMap(({ payload }) =>
      from(createClaimDocumentRef(payload.claimId).get()).pipe(
        map(documentSnapshot => {
          const claim = {
            ...documentSnapshot.data()!,
            id: documentSnapshot.id,
          };
          return ClaimsAction.fetchClaimSuccess(claim);
        }),
        catchError(error => of(ClaimsAction.fetchClaimFailure(error)))
      )
    )
  );

const deleteClaimEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.DeleteClaim),
    withLatestFrom(state$),
    filter(([, { auth }]) => auth.user.claims?.isAdmin),
    switchMap(([{ payload }]) =>
      forkJoin({
        usersWithStarredClaimsSnapshot: createUsersWithStarredClaimsCollectionGroup(payload.claimId).get(),
        callsSnapshot: createCallsCollectionGroup(payload.claimId).get(),
      }).pipe(
        map(querySnapshot => {
          const usersWithStarredClaimsDocRefs = querySnapshot.usersWithStarredClaimsSnapshot.docs.map(doc => doc.ref);
          const callsDocsRefs = querySnapshot.callsSnapshot.docs.map(doc => doc.ref);
          return { usersWithStarredClaimsDocRefs, callsDocsRefs };
        }),
        mergeMap(docsRefs => {
          const { callsDocsRefs, usersWithStarredClaimsDocRefs } = docsRefs;

          const batch = firebaseFirestore.batch();

          // INFO: delete given claim
          batch.delete(createClaimDocumentRef(payload.claimId));

          // // INFO: delete given claim calls
          callsDocsRefs.forEach(callDocRef => batch.delete(callDocRef));

          // // INFO: update starredClaimsIds for users
          usersWithStarredClaimsDocRefs.forEach(async userDocRef => {
            batch.update(userDocRef, {
              starredClaimsIds: firebase.firestore.FieldValue.arrayRemove(payload.claimId),
            });
          });

          return from(batch.commit()).pipe(
            mergeMap(() =>
              of(
                SharedAction.notifyUser(i18nClient.t('feed.claimDeleteSuccessMessage'))
                // ClaimsAction.deleteClaimSuccess()
              )
            ),
            catchError(error =>
              of(
                SharedAction.notifyUser(i18nClient.t('feed.claimDeleteFailureMessage'), 'error'),
                ClaimsAction.deleteClaimFailure(error)
              )
            )
          );
        }),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('feed.claimDeleteFailureMessage'), 'error'),
            ClaimsAction.deleteClaimFailure(error)
          )
        )
      )
    )
  );

// NOTE: get rid of this when we swap feed to observable
// const deleteClaimSuccessEpic: Epic<Action> = actions$ =>
//   actions$.pipe(
//     // ofType(ClaimsActionType.DeleteClaimSuccess),
//     // map(() => ClaimsAction.fetchClaims(QueryFilterType.Recent))
//   );

const toggleClaimVisibilityEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.ToggleClaimVisibility),
    withLatestFrom(state$),
    filter(([, { auth }]) => auth.user.claims?.isAdmin),
    switchMap(([{ payload }]) =>
      from(createClaimDocumentRef(payload.claimId).update('isVisible', payload.isVisible)).pipe(
        mergeMap(() =>
          of(
            SharedAction.notifyUser(i18nClient.t('feed.claimToggleVisibilitySuccessMessage')),
            ClaimsAction.toggleClaimVisibilitySuccess(payload.claimId)
          )
        ),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('feed.claimToggleVisibilityFailureMessage'), 'error'),
            ClaimsAction.toggleClaimVisibilityFailure(error)
          )
        )
      )
    )
  );

const toggleClaimVisibilitySuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ClaimsActionType.ToggleClaimVisibilitySuccess),
    map(({ payload }) => ClaimsAction.fetchClaim(payload.claimId))
  );

const fetchClaimsEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.FetchClaims),
    withLatestFrom(state$),
    switchMap(
      ([
        { payload },
        {
          auth,
          claims: {
            claimsList: { shouldUseQueryCursor, lastFilter },
          },
        },
      ]) => {
        const isAdmin = Boolean(auth.user.claims?.isAdmin);
        const filter = payload.queryFilter === undefined ? lastFilter : payload.queryFilter;
        const filterChanged = filter === lastFilter ? false : true;
        const currentFilter = payload.queryFilter ? payload.queryFilter : lastFilter;
        const currentQueryCursor = shouldUseQueryCursor && !filterChanged ? payload.queryCursor : undefined;
        const query = createClaimsQuery(isAdmin, currentFilter, currentQueryCursor, payload.queryCategory);

        return from(query.get()).pipe(
          map(querySnapshot => {
            if (querySnapshot.empty) {
              return ClaimsAction.fetchClaimsSuccess(
                [],
                currentQueryCursor,
                shouldUseQueryCursor,
                false,
                currentFilter
              );
            } else {
              const claims = querySnapshot.docs.map(queryDocumentSnapshot => ({
                ...queryDocumentSnapshot.data(),
                id: queryDocumentSnapshot.id,
              }));
              const queryCursor = querySnapshot.docs[querySnapshot.docs.length - 1];

              const nextQueryCursor = {
                ...queryCursor.data(),
                id: queryCursor.id,
              };
              const hasNextPage = querySnapshot.docs.length === 10;
              return ClaimsAction.fetchClaimsSuccess(
                claims,
                nextQueryCursor,
                shouldUseQueryCursor,
                hasNextPage,
                currentFilter
              );
            }
          }),
          catchError(error => of(ClaimsAction.fetchClaimsFailure(error)))
        );
      }
    )
  );

const togglePromotedClaimEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.ToggleClaimPromotion),
    withLatestFrom(state$),
    filter(([, { auth }]) => auth.user.claims?.isAdmin),
    switchMap(([{ payload }]) =>
      from(createClaimDocumentRef(payload.claimId).update('isPromoted', payload.isPromoted)).pipe(
        mergeMap(() =>
          of(
            SharedAction.notifyUser(i18nClient.t('feed.claimTogglePromotionSuccessMessage')),
            ClaimsAction.toggleClaimPromotionSuccess(payload.claimId)
          )
        ),
        catchError(error =>
          of(
            SharedAction.notifyUser(i18nClient.t('feed.claimTogglePromotionFailureMessage'), 'error'),
            ClaimsAction.toggleClaimPromotionFailure(error)
          )
        )
      )
    )
  );

const fetchPromotedClaimsEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.FetchPromotedClaims),
    withLatestFrom(state$),
    switchMap(([, { auth }]) => {
      const isAdmin = Boolean(auth.user.claims?.isAdmin);
      const query = createPromotedClaimsQuery(isAdmin);

      return from(query.get()).pipe(
        map(querySnapshot => {
          const promotedClaims = querySnapshot.docs.map(queryDocumentSnapshot => ({
            ...queryDocumentSnapshot.data(),
            id: queryDocumentSnapshot.id,
          }));

          return ClaimsAction.fetchPromotedClaimsSuccess(promotedClaims);
        }),
        catchError(error => of(ClaimsAction.fetchPromotedClaimsFailure(error)))
      );
    })
  );

const togglePromotedClaimSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ClaimsActionType.ToggleClaimPromotionSuccess),
    map(() => ClaimsAction.fetchPromotedClaims())
  );

const saveCustomClaimEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ClaimsActionType.SaveCustomClaim),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        customClaimCollectionRef.doc().set({
          sentenceText: payload.claimText,
          author: {
            id: auth.user.data?.uid!,
            avatar: auth.user.data?.photoURL || null,
            name: auth.user.data?.displayName || null,
          },
          sourceUrl: payload.claimUrl || null,
          category: payload.category || null,
          createdAt: firebase.firestore.Timestamp.now(),
          modifiedAt: firebase.firestore.Timestamp.now(),
          truthCount: 0,
          bullshitCount: 0,
          bsRate: 0,
          totalCount: 0,
          mergeId: null,
          isVisible: true,
          claimedBy: payload.claimedBy,
          isPromoted: false,
        })
      ).pipe(
        mergeMap(() => {
          return of(
            ClaimsAction.saveCustomClaimSuccess(),
            // ClaimsAction.fetchClaims(QueryFilterType.Recent),
            SharedAction.notifyUser(i18nClient.t('customClaimDialog.addCustomClaimSuccessMessage'), 'success')
          );
        }),
        catchError(error =>
          of(
            ClaimsAction.saveCustomClaimFailure(error),
            SharedAction.notifyUser(i18nClient.t('customClaimDialog.addCustomClaimErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const claimsEpics = combineEpics(
  fetchClaimEpic,
  deleteClaimEpic,
  toggleClaimVisibilityEpic,
  toggleClaimVisibilitySuccessEpic,
  fetchClaimsEpic,
  togglePromotedClaimEpic,
  togglePromotedClaimSuccessEpic,
  fetchPromotedClaimsEpic,
  saveCustomClaimEpic
);
