import React from 'react';
import { MdGroupWork } from 'react-icons/md';
import './TileCategory.scss';

interface TileCategoryProps {
  category: string;
}

export const TileCategory: React.FC<TileCategoryProps> = ({ category }) => {
  return (
    <div className="tile-category">
      <MdGroupWork className="tile-category__icon" />
      <span className="tile-category__text"> {category}</span>
    </div>
  );
};
