import { Claim, VoteStatus } from './types';
type ClaimStatus = {
  voteStatus: VoteStatus;
  votePercentage: number;
};

export const determineClaimVoteStatus = (
  claim: Pick<Claim, 'totalCount' | 'truthCount' | 'bullshitCount'>
): ClaimStatus => {
  const isTruth = claim.truthCount > claim.bullshitCount;
  const isTie = claim.truthCount === claim.bullshitCount;

  const voteBase = isTruth ? claim.truthCount / claim.totalCount : claim.bullshitCount / claim.totalCount;
  const votePercentage = Math.floor(Math.round(voteBase * 100));

  if (isTruth) {
    return {
      voteStatus: VoteStatus.Truth,
      votePercentage,
    };
  }

  if (isTie) {
    return {
      voteStatus: VoteStatus.Tie,
      votePercentage,
    };
  }

  return {
    voteStatus: VoteStatus.Bullshit,
    votePercentage,
  };
};

type PolygonPoints = {
  right: string;
  left: string;
};

export const createPolygonPoints = (
  barWidth: number,
  voteStatus: VoteStatus,
  votePercentage: number
): PolygonPoints => {
  if (votePercentage === 50) {
    const left = `0,0 0,40 ${barWidth / 2 - 20},40 ${barWidth / 2},0`;
    const right = `${barWidth / 2 + 20},0 ${barWidth / 2},40 ${barWidth},40 ${barWidth},0`;

    return { left, right };
  }

  const basePercentage = voteStatus === VoteStatus.Truth ? votePercentage : 100 - votePercentage;
  const scaledPercent = Math.round((barWidth * basePercentage) / 100);
  const left = `0,0 0,40 ${scaledPercent},40 ${scaledPercent + 20},0`;
  const right = `${scaledPercent},0 ${scaledPercent - 20},40 ${barWidth},40 ${barWidth},0`;

  return { left, right };
};
export const createPolygons = (voteStatus: VoteStatus, votePercentage: number): PolygonPoints => {
  const basePercentage = voteStatus === VoteStatus.Truth ? votePercentage : 100 - votePercentage;
  const scaledPercent = Math.round((217 * basePercentage) / 100);
  const left = `0,0 0,40 ${scaledPercent},40 ${scaledPercent + 20},0`;
  const right = `${scaledPercent},0 ${scaledPercent - 20},40 217,40 217,0`;
  return { left, right };
};
