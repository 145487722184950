import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending, isStatusFailure, isStatusSuccessPending, isStatusSuccess } from 'modules/store/status';
import { Claim } from 'modules/claims';
import { UserAction } from './actions';
import {
  selectUserData,
  selectUserVotedClaims,
  selectUserStarredClaims,
  selectUserCalls,
  selectDeleteUserCallStatus,
  selectSwapUserCallStatus,
  selectUserAddedClaims,
} from './selectors';
import type { CallVoteType, PersonalInfoType } from './types';

export const useUserManagement = () => {
  const dispatch = useDispatch();

  const userData = useSelector(selectUserData);
  const userCalls = useSelector(selectUserCalls);
  const userVotedClaims = useSelector(selectUserVotedClaims);
  const userStarredClaims = useSelector(selectUserStarredClaims);
  const userAddedClaims = useSelector(selectUserAddedClaims);
  const deleteUserCallStatus = useSelector(selectDeleteUserCallStatus);
  const swapUserCallStatus = useSelector(selectSwapUserCallStatus);

  const isLoading =
    isStatusPending(userData.status) ||
    isStatusPending(userCalls.status) ||
    isStatusPending(userStarredClaims.status) ||
    isStatusPending(userVotedClaims.status) ||
    isStatusPending(deleteUserCallStatus) ||
    isStatusPending(swapUserCallStatus);
  const isErrored =
    isStatusFailure(userData.status) ||
    isStatusFailure(userCalls.status) ||
    isStatusFailure(deleteUserCallStatus) ||
    isStatusFailure(swapUserCallStatus);

  const isRefetching = isStatusSuccessPending(userData.status);

  const isUserDataLoaded = isStatusSuccess(userData.status);

  const checkIfStarred = (claimId: string) => userData.starredClaimsIds.includes(claimId);

  const fetchUserData = useCallback(() => {
    dispatch(UserAction.fetchUserData());
  }, [dispatch]);

  const fetchUserVotedClaims = useCallback(() => {
    dispatch(UserAction.fetchUserVotedClaims());
  }, [dispatch]);

  const fetchUserAddedClaims = useCallback(() => {
    dispatch(UserAction.fetchUserAddedClaims());
  }, [dispatch]);

  const fetchUserStarredClaims = useCallback(() => {
    dispatch(UserAction.fetchUserStarredClaims());
  }, [dispatch]);

  const saveUserStarredClaimId = useCallback(
    (starredClaimId: string) => {
      dispatch(UserAction.saveUserStarredClaimId(starredClaimId));
    },
    [dispatch]
  );

  const deleteUserStarredClaimId = useCallback(
    (starredClaimId: string) => {
      dispatch(UserAction.deleteUserStarredClaimId(starredClaimId));
    },
    [dispatch]
  );

  const saveUserHiddenClaimId = useCallback(
    (hiddenClaimId: string) => {
      dispatch(UserAction.saveUserHiddenClaimId(hiddenClaimId));
    },
    [dispatch]
  );
  const fetchUserCalls = useCallback(() => {
    dispatch(UserAction.fetchUserCalls());
  }, [dispatch]);

  const saveUserCall = useCallback(
    (claimId: string, voteType: CallVoteType) => {
      dispatch(UserAction.saveUserCall(claimId, voteType));
    },
    [dispatch]
  );

  const deleteUserCall = useCallback(
    (claimId: string, callId: string, voteType: CallVoteType) => {
      dispatch(UserAction.deleteUserCall(claimId, callId, voteType));
    },
    [dispatch]
  );

  const swapUserCall = useCallback(
    (claim: Claim, callId: string, voteType: CallVoteType) => {
      dispatch(UserAction.swapUserCall(claim, callId, voteType));
    },
    [dispatch]
  );

  const saveUserPersonalInfo = useCallback(
    ({ name, age, location }: PersonalInfoType) => {
      dispatch(UserAction.saveUserPersonalInfo({ name, age, location }));
    },
    [dispatch]
  );

  const saveReportedClaim = useCallback(
    (reportedClaimId: string, reason: string) => {
      dispatch(UserAction.saveReportedClaim(reportedClaimId, reason));
    },
    [dispatch]
  );
  return {
    isLoading,
    isErrored,
    isRefetching,
    isUserDataLoaded,

    lastVisited: userData.lastVisited,
    bookmarks: userData.bookmarks,
    userStarredClaimsIds: userData.starredClaimsIds,
    userStarredClaims: userStarredClaims.data,
    userVotedClaims: userVotedClaims.data,
    userAddedClaims: userAddedClaims.data,
    userPersonalInfo: userData.userPersonalInfo || {},
    userCalls: userCalls.data,
    userName: userData.userPersonalInfo?.name,
    userHiddenClaims: userData.hiddenClaimsIds,
    userReportedClaims: userData.reportedClaims,
    userReportedClaimsIds: userData.reportedClaims.map(value => value.claimId),
    fetchUserData,
    fetchUserVotedClaims,
    fetchUserStarredClaims,
    saveUserStarredClaimId,
    deleteUserStarredClaimId,
    fetchUserCalls,
    saveUserCall,
    deleteUserCall,
    swapUserCall,
    checkIfStarred,
    saveUserPersonalInfo,
    saveUserHiddenClaimId,
    saveReportedClaim,
    fetchUserAddedClaims,
  };
};
