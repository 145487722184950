import type { Action } from 'modules/store/action';
import { Status, isStatusSuccess } from 'modules/store/status';
import { RssActionType } from './actions';
import type { RssState } from './state';

export const rssState: RssState = {
  urls: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
};

export const rssReducer = (state = rssState, action: Action): RssState => {
  switch (action.type) {
    case RssActionType.FetchRssInfo:
      return {
        ...state,
        urls: {
          ...state.urls,
          error: null,
          status: isStatusSuccess(state.urls.status) ? Status.SUCCESS_PENDING : Status.PENDING,
        },
      };

    case RssActionType.FetchRssInfoSuccess:
      return {
        ...state,
        urls: {
          data: action.payload.urls,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case RssActionType.FetchRssInfoFailure:
      return {
        ...state,
        urls: {
          ...state.urls,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };
    default:
      return state;
  }
};
