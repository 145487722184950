import React from 'react';

import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { i18nClient } from 'services/translations';

import './SocialsShare.scss';

interface SocialsShareProps {
  claimId: string;
}

export const SocialsShare: React.FC<SocialsShareProps> = ({ claimId }) => {
  const claimUrl = `${process.env.REACT_APP_URL}/claims/${claimId}`;
  return (
    <div className="social-icons">
      <FacebookShareButton url={claimUrl} quote={i18nClient.t('customClaimDialog.shareMessage')}>
        <FacebookIcon size={23} round />
      </FacebookShareButton>

      <RedditShareButton url={claimUrl} title={i18nClient.t('customClaimDialog.shareMessage')}>
        <RedditIcon size={23} round />
      </RedditShareButton>
      <TwitterShareButton url={claimUrl} title={i18nClient.t('customClaimDialog.shareMessage')}>
        <TwitterIcon size={23} round />
      </TwitterShareButton>
    </div>
  );
};
