import React from 'react';
import './ViewSwitch.scss';
import { useLayoutManagement } from '../../../../modules/layout/hooks';
import { ViewTypes } from '../../../../modules/layout';

export const ViewSwitch: React.FC = () => {
  const { viewType, setViewType } = useLayoutManagement();

  return (
    <div className="view-switch">
      <div
        className={`view-switch__item ${viewType === ViewTypes.tiles ? 'active' : ''}`}
        onClick={() => setViewType(ViewTypes.tiles)}
      >
        <i className="material-icons">view_module</i>
      </div>
      <div
        className={`view-switch__item ${viewType === ViewTypes.list ? 'active' : ''}`}
        onClick={() => setViewType(ViewTypes.list)}
      >
        <i className="material-icons">view_stream</i>
      </div>
    </div>
  );
};
