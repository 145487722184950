import type { Action } from 'modules/store/action';
import { SharedActionType } from './actions';
import type { SharedState } from './state';

export const sharedState: SharedState = {
  notificationQueue: [],
};

export function sharedReducer(state = sharedState, action: Action): SharedState {
  switch (action.type) {
    /**
     * NotifyUser
     */
    case SharedActionType.NotifyUser:
      return {
        ...state,
        notificationQueue: [...state.notificationQueue, action.payload],
      };

    /**
     * NotifyUserSuccess
     */
    case SharedActionType.NotifyUserSuccess:
      return {
        ...state,
        notificationQueue: state.notificationQueue.slice(1),
      };

    default:
      return state;
  }
}
