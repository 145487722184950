import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import logo from '../../../../assets/icbs_logo.svg';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useLayoutManagement } from '../../../../modules/layout/hooks';
import { useAuthManagement } from '../../../../modules/auth';

import { Button, Icon, Input } from '../../../../shared';
import { i18nClient } from 'services/translations';

import './LostPass.scss';

const lostPasswordFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
}).required();

interface LostPasswordFormData {
  email: string;
}

// TODO: add translation support
export const LostPass: React.VFC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { showModal, hideModal } = useLayoutManagement();
  const { user, resetUserPassword, isErrored, resetAuthErrors, isResetPasswordSent } = useAuthManagement();

  const { errors, handleSubmit, control } = useForm<LostPasswordFormData>({
    mode: 'onTouched',
    resolver: joiResolver(lostPasswordFormSchema),
  });

  const isEmailError = Boolean(errors.email);

  const history = useHistory();

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
      if (isErrored) {
        resetAuthErrors();
      }
    };
  }, [hideModal, isErrored, resetAuthErrors, showModal]);

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [history, user]);

  const handleSendEmail = handleSubmit(({ email }) => {
    resetUserPassword(email);
    setIsEmailSent(true);
  });

  const handleExit = () => history.push('/');

  return (
    <>
      <div className="lostpass">
        <div className="lostpass__topbar">
          <img className="lostpass__logo" alt="I call BS" src={logo} />
          <Icon className="lostpass__icon-right" type="clear" onClick={handleExit} />
        </div>
        <h2 className="lostpass__header">Reset password</h2>
        <div>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={field => (
              <Input
                name={field.name}
                onChange={field.onChange}
                type="text"
                label="Email"
                value={field.value}
                error={isEmailError && errors.email!.message}
              />
            )}
          />
          {isResetPasswordSent && (
            <div className="lostpass__confirmation">{i18nClient.t('auth.resetPasswordSuccessMessage')}</div>
          )}
          {isErrored && <div className="error">{i18nClient.t('general.failureMessage')}</div>}
          <div className="lostpass__buttons">
            <Button onClick={handleSendEmail} style="dark" disabled={isEmailSent}>
              Send new
            </Button>

            <Button icon="done" link="/login" type="light">
              Login
            </Button>
          </div>
        </div>
      </div>
      <div className="login__backdrop" onClick={handleExit} />
    </>
  );
};
