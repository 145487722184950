import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdThumbUp } from 'react-icons/md';
import './TileClaimedBy.scss';

interface TileSourceProps {
  claimedBy: string;
}

export const TileClaimedBy: React.FC<TileSourceProps> = ({ claimedBy }) => {
  const { t } = useTranslation();

  return (
    <div className="tile-claimedby">
      <MdThumbUp className="tile-claimedby__icon" />
      <span className="tile-claimedby__text">{`${t('tile.claimedByLabel')} ${claimedBy}`}</span>
    </div>
  );
};
