import React, { ChangeEvent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUserManagement } from 'modules/user';

interface ClaimDialogProps {
  isDialogVisible: boolean;
  handleDialogToggle: () => void;
  claimId: string;
}
export const reasons = [
  { id: 'attack', text: 'Attacked because of my identity' },
  { id: 'harass', text: 'Harassed or intimidated with violence' },
  { id: 'spam', text: 'Spammed' },
  { id: 'harm', text: 'Shown content related to self-harm' },
  { id: 'sensitive', text: 'Shown sensitive or disturbing content' },
];

export const ReportDialog: React.FC<ClaimDialogProps> = ({ isDialogVisible, handleDialogToggle, claimId }) => {
  const [reason, setReason] = React.useState('');
  const { t } = useTranslation();
  const { saveReportedClaim } = useUserManagement();
  const handleReportClaim = () => {
    saveReportedClaim(claimId, reason);
    handleDialogToggle();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setReason(event.target.value);
  };
  return (
    <Dialog open={isDialogVisible} onClose={handleDialogToggle} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('customClaimDialog.reportClaimLabel')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('customClaimDialog.reportClaimInfoLabel')}</DialogContentText>
        <FormControl fullWidth margin="dense">
          <InputLabel id="reportReason">{t('customClaimDialog.reportReason')}</InputLabel>
          <Select
            labelId="reportReason"
            id="reportReason"
            name="reportReason"
            value={reason}
            fullWidth
            label="reportReason"
            onChange={handleChange}
          >
            {reasons.map(reason => (
              <MenuItem key={reason.id} value={reason.id}>
                {reason.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button onClick={handleDialogToggle} color="secondary">
          {t('general.cancelAction')}
        </Button>
        <Button color="primary" type="submit" onClick={handleReportClaim}>
          {t('customClaimDialog.reportButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
