import { useState } from 'react';
import { ClaimsCategory } from 'features/Claims/consts';
import { useClaimsManagement } from 'modules/claims';
import { Dropdown } from 'shared/Dropdown/Dropdown';

export const CategoryFilters = () => {
  const { category: currentCategory, changeClaimsCategory } = useClaimsManagement();
  const [category, setCategory] = useState<ClaimsCategory>(currentCategory);

  const handleChange = event => {
    setCategory(event.target.value);
    changeClaimsCategory(event.target.value);
  };

  const categories = Object.keys(ClaimsCategory) as Array<keyof typeof ClaimsCategory>;

  return <Dropdown handleChange={handleChange} categories={categories} value={category} id="category" />;
};
