import React from 'react';
import TruthIcon from '../TruthIcon/TruthIcon';
import BSIcon from '../BSIcon/BSIcon';
import { useTranslation } from 'react-i18next';

interface VotedLabelProps {
  hasVotedTruth: boolean;
}

export const VotedLabel: React.VFC<VotedLabelProps> = ({ hasVotedTruth }) => {
  const { t } = useTranslation();

  return hasVotedTruth ? (
    <div className="tile-voted-panel__button truth">
      <TruthIcon />
      <span>{t('tile.truthLabel')}</span>
    </div>
  ) : (
    <button className="tile-voted-panel__button bullshit">
      <BSIcon />
      <span>{t('tile.bsLabel')}</span>
    </button>
  );
};
