import React from 'react';
import { Icon } from '../../../shared';
import './DashButton.scss';

type DashButtonProps = {
  icon: string;
  desc: string;
  selected?: boolean;
  number: number;
  handleClick: () => void;
};

export const DashButton = ({ icon, number = 0, desc, selected, handleClick }: DashButtonProps) => (
  <div onClick={handleClick} className={`dashboard-button ${selected ? `active` : ''}`}>
    <div>
      <Icon className="dashboard-button__icon" type={icon} />
      <span className="dashboard-button__numbers">{number}</span>
    </div>
    <div className="dashboard-button__desc">{desc}</div>
  </div>
);
