import React from 'react';
import { useHistory } from 'react-router';
import { useUserManagement, CallVoteType } from 'modules/user';
import BSIcon from '../../Tile/BSIcon/BSIcon';
import TruthIcon from '../../Tile/TruthIcon/TruthIcon';
import './ListVote.scss';
import { useAuthManagement } from 'modules/auth/hooks';
import { useTranslation } from 'react-i18next';
import { Claim } from 'modules/claims';

interface TileVoteProps {
  claim: Claim;
}

export const ListVote: React.FC<TileVoteProps> = ({ claim }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { saveUserCall } = useUserManagement();
  const { isLoggedIn } = useAuthManagement();

  const handleClaimVote = (voteType: CallVoteType) => (): void => {
    if (isLoggedIn) {
      saveUserCall(claim.id, voteType);
    } else {
      history.push('/login');
    }
  };

  return (
    <div className="list-tile-vote">
      <div className="list-tile-vote__button-container">
        <button className="list-tile-vote__button" onClick={handleClaimVote(CallVoteType.Truth)}>
          <TruthIcon />
          <span>{t('tile.truthLabel')}</span>
        </button>
        <button className="list-tile-vote__button" onClick={handleClaimVote(CallVoteType.Bullshit)}>
          <BSIcon />
          <span>{t('tile.bsLabel')}</span>
        </button>
      </div>
    </div>
  );
};
