import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutAction } from './actions';
import { selectIsModalShown, selectViewType } from './selectors';
import { ViewTypes } from './types';

export const useLayoutManagement = () => {
  const dispatch = useDispatch();

  const isModalShown = useSelector(selectIsModalShown);
  const viewType = useSelector(selectViewType);

  const showModal = useCallback(() => {
    dispatch(LayoutAction.showModal());
  }, [dispatch]);

  const hideModal = useCallback(() => {
    dispatch(LayoutAction.hideModal());
  }, [dispatch]);

  const setViewType = useCallback(
    (viewType: ViewTypes) => {
      dispatch(LayoutAction.setViewType(viewType));
    },
    [dispatch]
  );

  return {
    isModalShown,
    showModal,
    hideModal,

    viewType,
    setViewType,
  };
};
