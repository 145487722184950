import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const MenuIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="14px"
      height="14px"
      {...props}
    >
      <path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="2" d="M2 12L22 12M2 6L22 6M2 18L22 18" />
    </SvgIcon>
  );
};
