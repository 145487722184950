import React, { ChangeEvent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MuiThemeProvider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { i18nClient } from 'services/translations';
import { useClaimsManagement } from 'modules/claims';
import { ClaimsCategory } from 'features/Claims/consts';

const customClaimFormSchema = Joi.object({
  claimText: Joi.string()
    .required()
    .min(10)
    .max(500)
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.min': i18nClient.t('customClaimDialog.minMaxErrorLabel'),
      'string.max': i18nClient.t('customClaimDialog.minMaxErrorLabel'),
    }),
  claimUrl: Joi.string()
    .allow('')
    .optional()
    .uri()
    .messages({
      'string.uri': i18nClient.t('customClaimDialog.notUrlErrorLabel'),
    }),
  claimedBy: Joi.string()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
    }),
}).required();

interface CustomClaimFormData {
  claimText: string;
  claimUrl?: string;
  claimedBy: string;
  category: string;
}

interface ClaimDialogProps {
  isDialogVisible: boolean;
  handleDialogToggle: () => void;
}

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
  },
});

export const ClaimDialog: React.FC<ClaimDialogProps> = ({ isDialogVisible, handleDialogToggle }) => {
  const [category, setCategory] = React.useState('');
  const { t } = useTranslation();
  const { errors, handleSubmit, register } = useForm<CustomClaimFormData>({
    mode: 'onTouched',
    resolver: joiResolver(customClaimFormSchema),
  });
  const { saveCustomClaim } = useClaimsManagement();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCategory(event.target.value);
  };

  const claimTextErrorMessage = errors.claimText?.message;
  const claimUrlErrorMessage = errors.claimUrl?.message;
  const claimedByErrorMessage = errors.claimedBy?.message;

  return (
    <Dialog open={isDialogVisible} onClose={handleDialogToggle} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('customClaimDialog.addClaimLabel')}</DialogTitle>
      <MuiThemeProvider theme={formLabelsTheme}>
        <form
          noValidate
          onSubmit={handleSubmit(({ claimText, claimedBy, claimUrl = null }) => {
            handleDialogToggle();
            saveCustomClaim(claimText, claimedBy, claimUrl, category);
          })}
        >
          <DialogContent>
            <DialogContentText>{t('customClaimDialog.addClaimInfoLabel')}</DialogContentText>
            <TextField
              inputRef={register}
              name="claimText"
              id="claimText"
              autoFocus
              margin="dense"
              label={t('customClaimDialog.claimLabel')}
              type="text"
              fullWidth
              required
              multiline
              minRows={5}
              error={Boolean(errors.claimText)}
              helperText={claimTextErrorMessage}
            />
            <TextField
              inputRef={register}
              name="claimUrl"
              id="claimUrl"
              margin="dense"
              label={t('customClaimDialog.sourceLabel')}
              type="text"
              fullWidth
              error={Boolean(errors.claimUrl)}
              helperText={claimUrlErrorMessage}
            />
            <TextField
              inputRef={register}
              name="claimedBy"
              id="claimedBy"
              margin="dense"
              defaultValue="ICallBS"
              label={t('customClaimDialog.claimedByLabel')}
              type="text"
              fullWidth
              required
              error={Boolean(errors.claimedBy)}
              helperText={claimedByErrorMessage}
            />
            <FormControl fullWidth required margin="dense">
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                inputRef={register}
                id="category"
                name="category"
                value={category}
                fullWidth
                label="Category"
                onChange={handleChange}
                error={Boolean(errors.category)}
              >
                {(Object.keys(ClaimsCategory) as Array<keyof typeof ClaimsCategory>).map(category => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ marginTop: 20 }}>
            <Button onClick={handleDialogToggle} color="secondary">
              {t('general.cancelAction')}
            </Button>
            <Button color="primary" type="submit">
              {t('customClaimDialog.addClaimButtonLabel')}
            </Button>
          </DialogActions>
        </form>
      </MuiThemeProvider>
    </Dialog>
  );
};
