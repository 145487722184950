import React from 'react';
import { createPolygonPoints, VoteStatus } from '../../../../../../modules/claims';

type TieProps = {
  votePercentage: number;
  barWidth: number;
};
const Tie: React.VFC<TieProps> = ({ votePercentage, barWidth }) => {
  const { left, right } = createPolygonPoints(barWidth, VoteStatus.Truth, votePercentage);

  return (
    <>
      <span className="tile-rate__result tile-rate__result--truth">
        {votePercentage}
        <span className="tile-rate__percent">%</span>
      </span>
      <span className="tile-rate__result tile-rate__result--bullshit">
        {votePercentage}
        <span className="tile-rate__percent">%</span>
      </span>
      <svg className="tile-rate__progress-bar" height="40" width="212">
        <polygon className="tile-rate__bar--green" points={left} />
        <polygon className="tile-rate__bar--red" points={right} />
      </svg>
    </>
  );
};

export default Tie;
