import { useState } from 'react';
import { BetterDropdown } from 'shared/Dropdown/BetterDropdown';
import { SortingTypes } from 'features/Claims/consts';

interface SortingProps {
  handleSortingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Sorting: React.FC<SortingProps> = ({ handleSortingChange }) => {
  const [sorting, setSorting] = useState<any>(SortingTypes[0].key);
  const handleChange = event => {
    setSorting(event.target.value);
    handleSortingChange(event.target.value);
  };
  return <BetterDropdown handleChange={handleChange} items={SortingTypes} value={sorting} id="sorting" />;
};
