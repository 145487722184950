import React from 'react';
import type firebase from 'firebase/app';
import { format } from 'date-fns';
import { CallVoteType, useUserManagement } from 'modules/user';
import { Claim } from 'modules/claims';
import { useTranslation } from 'react-i18next';
import { MdRecordVoiceOver } from 'react-icons/md';
import { EditRemoveCallButton } from './EditRemoveCallButton';
import { ButtonTypes } from './types';
import { VotedLabel } from './VotedLabel';
import './TileVotedPanel.scss';

interface TileVotedPanelProps {
  claim: Claim;
  userCallId: string;
  userCallType: CallVoteType;
  userCallCreatedAt: firebase.firestore.Timestamp;
}

export const TileVotedPanel: React.VFC<TileVotedPanelProps> = ({
  claim,
  userCallId,
  userCallType,
  userCallCreatedAt,
}) => {
  const { deleteUserCall, swapUserCall } = useUserManagement();
  const { t } = useTranslation();
  const hasVotedTruth = userCallType === CallVoteType.Truth;

  const handleOnPressDelete = (): void => {
    deleteUserCall(claim.id, userCallId, userCallType);
  };

  const handleOnPressSwap = (): void => {
    swapUserCall(claim, userCallId, userCallType);
  };

  return (
    <div className="tile-voted-panel-container">
      <div className="tile-voted-panel__header">
        <div>
          <MdRecordVoiceOver className="tile-voted-panel__header-icon" />
          <span className="tile-vote__title">{t('tile.calledLabel')}</span>
        </div>
        <span className="tile-header__first-section__time">{format(userCallCreatedAt.toDate(), 'dd LLL yyyy')}</span>
      </div>
      <div className="tile-voted-panel__controls-container">
        <VotedLabel hasVotedTruth={hasVotedTruth} />
        <div className="tile-voted-panel__buttons-wrapper">
          <EditRemoveCallButton buttonType={ButtonTypes.remove} onClick={handleOnPressDelete} />
          <EditRemoveCallButton buttonType={ButtonTypes.swap} onClick={handleOnPressSwap} />
        </div>
      </div>
    </div>
  );
};
