import type firebase from 'firebase/app';
import type { Author } from 'modules/user';

export type Claim = {
  id: string;
  sentenceText: string;
  author: Author;
  sourceUrl: string | null;
  category: string | null;
  createdAt: firebase.firestore.Timestamp;
  modifiedAt: firebase.firestore.Timestamp;
  truthCount: number;
  bsRate: number;
  bullshitCount: number;
  totalCount: number;
  mergeId: string | null;
  isVisible: boolean;
  isPromoted: boolean;
  claimedBy?: string | undefined;
};

export enum VoteStatus {
  Truth = 'truth',
  Bullshit = 'bullshit',
  Tie = 'tie',
}

// NOTE: left commneted code as we will use it during working on ICB-10

export enum QueryFilterType {
  Recent = 'recent',
  // Trending = 'trending',
  Popular = 'popular',
  MostTruths = 'mostTruths',
  MostBs = 'mostBs',
  CloseCall = 'closeCall',
}
