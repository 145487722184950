import { ClaimsCategory } from 'features/Claims/consts';
import { useClaimsManagement } from 'modules/claims';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Source } from 'shared';
import { Dropdown } from 'shared/Dropdown/Dropdown';
import './RssCard.scss';

type RssCardProps = {
  title: string;
  contentSnippet: string;
  date: string;
  link: string;
  handleApprove: (title, link, category) => void;
  handleDeny: (title) => void;
};
export const RssCard: React.FC<RssCardProps> = ({ title, contentSnippet, date, link, handleApprove, handleDeny }) => {
  const { t } = useTranslation();
  const { category: currentCategory } = useClaimsManagement();
  const [category, setCategory] = useState<ClaimsCategory>(currentCategory);
  const handleChange = event => {
    setCategory(event.target.value);
  };
  const categories = Object.keys(ClaimsCategory) as Array<keyof typeof ClaimsCategory>;
  const disabledApprove = category === 'All';

  return (
    <div className="rsscard-wrapper">
      <div className="rsscard-info-section">
        <div className="rsscard-header">
          <h3>{title}</h3>
          <span>{date}</span>
        </div>
        <div className="rsscard-paragraph">{contentSnippet}</div>
        <Source source={link} />
      </div>
      <div className="rsscard-button-section">
        <div className="rsscard-button-category">
          <Dropdown categories={categories} value={category} id="claimcategory" handleChange={handleChange} />
          <Button
            icon="done"
            style="dark"
            onClick={() => handleApprove(title, link, category)}
            disabled={disabledApprove}
          >
            {t('rss.approve')}
          </Button>
        </div>
        <Button icon="clear" style="white" onClick={() => handleDeny(title)}>
          {t('rss.deny')}
        </Button>
      </div>
    </div>
  );
};
