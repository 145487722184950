import React, { Dispatch, SetStateAction } from 'react';
import './ActivityDashboard.scss';
import { DashButton } from 'scenes/Activity/DashButton/DashButton';
import { useUserManagement } from 'modules/user';
import { ClaimsTypes } from 'scenes/Activity/types';

type ActivityDashboardProps = {
  claimsType: string;
  userStarredClaimsLength: number;
  setClaimsType: Dispatch<SetStateAction<ClaimsTypes>>;
  userVotedClaimsLength: number;
  userAddedClaimsLength: number;
};
export const ActivityDashboard: React.FC<ActivityDashboardProps> = ({
  claimsType,
  setClaimsType,
  userVotedClaimsLength,
  userStarredClaimsLength,
  userAddedClaimsLength,
}) => {
  const { userName } = useUserManagement();
  return (
    <div className="dashboard">
      <div className="dashboard__profile">
        <div className="dashboard__greetings">Hello {userName}</div>
      </div>
      <div className="dashboard__buttons">
        <DashButton
          icon="record_voice_over"
          number={userVotedClaimsLength}
          desc="Voted claims"
          selected={claimsType === ClaimsTypes.voted}
          handleClick={() => setClaimsType(ClaimsTypes.voted)}
        />
        <DashButton
          icon="star"
          number={userStarredClaimsLength}
          desc="Starred claims"
          selected={claimsType === ClaimsTypes.starred}
          handleClick={() => setClaimsType(ClaimsTypes.starred)}
        />
        <DashButton
          icon="add"
          number={userAddedClaimsLength}
          desc="Added claims"
          selected={claimsType === ClaimsTypes.added}
          handleClick={() => setClaimsType(ClaimsTypes.added)}
        />
      </div>
    </div>
  );
};
