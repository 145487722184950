import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthAction } from './actions';
import { isStatusFailure, isStatusSuccess } from 'modules/store/status';
import { selectUser, selectLoginState, selectRegisterState, selectResetPasswordState } from './selectors';
import { UserCredentials } from './types';

export const useAuthManagement = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const loginState = useSelector(selectLoginState);
  const registerState = useSelector(selectRegisterState);
  const { isTokenVerified } = registerState;
  const resetPasswordState = useSelector(selectResetPasswordState);

  const isResetPasswordSent = isStatusSuccess(resetPasswordState.status);
  const isLoggedIn = Boolean(user.data?.uid);

  const { isAdmin } = user.claims || { isAdmin: false };

  const isErrored =
    isStatusFailure(loginState.status) ||
    isStatusFailure(registerState.status) ||
    isStatusFailure(resetPasswordState.status);

  const startAuthObserver = useCallback(() => {
    dispatch(AuthAction.startAuthObserver());
  }, [dispatch]);

  const resetAuthErrors = useCallback(() => {
    dispatch(AuthAction.resetAuthErrors());
  }, [dispatch]);

  const loginUser = useCallback(
    (credentials: UserCredentials) => {
      dispatch(AuthAction.loginUser(credentials));
    },
    [dispatch]
  );

  const logoutUser = useCallback(() => {
    dispatch(AuthAction.logoutUser());
  }, [dispatch]);

  const registerUser = useCallback(
    (credentials: UserCredentials) => {
      dispatch(AuthAction.registerUser(credentials));
    },
    [dispatch]
  );

  const verifyCaptcha = useCallback(
    (token: string) => {
      dispatch(AuthAction.verifyCaptcha(token));
    },
    [dispatch]
  );

  const setTokenExpired = useCallback(() => {
    dispatch(AuthAction.clearRecaptchaToken());
  }, [dispatch]);

  const resetUserPassword = useCallback(
    (email: string) => {
      dispatch(AuthAction.resetUserPassword(email));
    },
    [dispatch]
  );

  const changeUserPassword = useCallback(
    (newPassword: UserCredentials['password']) => {
      dispatch(AuthAction.changeUserPassword(newPassword));
    },
    [dispatch]
  );

  const deleteUser = useCallback(() => {
    dispatch(AuthAction.deleteUser());
  }, [dispatch]);

  return {
    isErrored,

    user: user.data,
    isLoggedIn,
    isAdmin,
    isTokenVerified,
    startAuthObserver,
    loginUser,
    logoutUser,
    registerUser,
    resetUserPassword,
    isResetPasswordSent,
    changeUserPassword,
    deleteUser,
    verifyCaptcha,
    setTokenExpired,
    resetAuthErrors,
  };
};
