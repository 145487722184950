import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Collapse, IconButton } from '@material-ui/core';
import { VisibilityIcon, VisibilityOffIcon } from 'assets/icons';
import { Claim } from 'modules/claims';
import './TileControls.scss';
import { useUserManagement } from 'modules/user';
import { MdFlag } from 'react-icons/md';
import { ReportDialog } from './ReportDialog/ReportDialog';

interface TileControlsProps {
  isVisible: boolean;
  claim: Claim;
}

export const TileControls: React.VFC<TileControlsProps> = ({ isVisible, claim }) => {
  const { t } = useTranslation();
  const { saveUserHiddenClaimId } = useUserManagement();
  const toggleVisibilityLabel = claim.isVisible ? t('feed.hideActionLabel') : t('feed.showActionLabel');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const handleDialogToggle = () => setDialogVisible(!isDialogVisible);
  return (
    <>
      {!claim.isPromoted && (
        <Collapse className="tile__collapse" in={isVisible}>
          <div className="tile__controls">
            <Tooltip title={toggleVisibilityLabel} placement="top" arrow>
              <IconButton onClick={() => saveUserHiddenClaimId(claim.id)}>
                {claim.isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={toggleVisibilityLabel} placement="top" arrow>
              <IconButton onClick={handleDialogToggle}>
                <MdFlag />
              </IconButton>
            </Tooltip>
          </div>
        </Collapse>
      )}
      {isDialogVisible && (
        <ReportDialog isDialogVisible={isDialogVisible} handleDialogToggle={handleDialogToggle} claimId={claim.id} />
      )}
    </>
  );
};
