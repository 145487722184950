import { Box, FormControl, Select, MenuItem } from '@material-ui/core';
import './Dropdown.scss';
import React from 'react';
import { Icon } from '../../shared/Icon/Icon';

type item = {
  key: string;
  name: string;
  icon?: string;
};
interface BetterDropdownProps {
  id: string;
  items: item[];
  value: item;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BetterDropdown: React.FC<BetterDropdownProps> = ({ id, items, value, handleChange }) => {
  return (
    <Box className="dropdown-wrapper">
      <FormControl fullWidth className="dropdown-form">
        <Select
          labelId={id}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          disableUnderline
        >
          {items.map(item => (
            <MenuItem key={item.key} value={item.key} className="dropdown-item">
              {item.icon ? (
                <>
                  <Icon type={item.icon} className="dropdown-icon" />
                  {item.name}
                </>
              ) : (
                <>{item.name}</>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
