import type firebase from 'firebase';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

const isDocumentDataPresent = <T extends Record<string, unknown>>(documentData: T | undefined): documentData is T => {
  return documentData ? Object.keys(documentData).length >= 1 : false;
};

export const filterEmptyDocument = () => {
  return <T extends Record<string, unknown>>(source: Observable<firebase.firestore.DocumentSnapshot<T>>) => {
    return source.pipe(
      filter(documentSnapshot => documentSnapshot.exists),
      map(documentSnapshot => documentSnapshot.data()),
      filter(isDocumentDataPresent)
    );
  };
};
