import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import './Button.scss';

export const Button = props => {
  const LinkWrapper = ({ children }) => (
    <Link to={props.link} className="btn__link">
      {children}
    </Link>
  );

  const ButtonComponent = () => (
    <button
      type={props.type}
      className={`btn ${props.style ? `btn--${props.style}` : 'btn--light'} ${props.disabled && `disabled`}`}
      onClick={props.onClick || null}
      disabled={props.disabled}
    >
      {props.icon && <Icon className="btn__icon" type={props.icon} />}
      {props.children}
    </button>
  );

  return (
    <>
      {(props.link && (
        <LinkWrapper>
          <ButtonComponent />
        </LinkWrapper>
      )) || <ButtonComponent />}
    </>
  );
};
