import { useClaimsManagement } from 'modules/claims';
import { useRssFeed } from 'modules/rss';
import React, { useEffect, useState } from 'react';
import Parser from 'rss-parser';
import rssParser from 'rss-parser';
import { RssCard } from '../RssCard/RssCard';

type RssFeedProps = {
  url: string;
};

type ArticleType = {
  categories?: string[];
  content?: string;
  contentSnippet?: string;
  creator?: string;
  isoDate?: string;
  link?: string;
  title?: string;
};
export const RssFeed: React.FC<RssFeedProps> = ({ url }) => {
  const parser: Parser<ArticleType> = new rssParser();
  const { saveCustomClaim } = useClaimsManagement();
  const { isSuccess } = useRssFeed();
  const [articles, setArticles] = useState<ArticleType[] | null>(null);
  const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';
  const getFeed = async () => {
    await parser.parseURL(CORS_PROXY + url, function (err, feed) {
      if (err) throw err;
      setArticles(feed.items);
    });
  };

  useEffect(() => {
    getFeed();
  }, [url]);

  const handleApprove = (title, link, category) => {
    try {
      saveCustomClaim(title, 'ICallBS', link, category);
    } catch {
      return;
    }
    removeFromArticles(title);
  };
  const handleDeny = title => {
    removeFromArticles(title);
  };

  const removeFromArticles = title => {
    const newArticles = articles.filter(article => {
      return article.title !== title;
    });
    setArticles(newArticles);
  };

  const formatDate = date => {
    const newDate = new Date(date);
    return newDate.toDateString();
  };
  return (
    <>
      {articles && isSuccess && (
        <>
          {articles.map(article => {
            return (
              <RssCard
                key={article.link}
                title={article.title}
                contentSnippet={article.contentSnippet}
                date={formatDate(article.isoDate)}
                link={article.link}
                handleApprove={handleApprove}
                handleDeny={handleDeny}
              />
            );
          })}
        </>
      )}
    </>
  );
};
