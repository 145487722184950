import React, { useEffect, useState } from 'react';
import { useAuthManagement } from 'modules/auth/hooks';
import { Topbar } from 'shared';
import './RssContainer.scss';
import { useLayoutManagement } from '../../modules/layout/hooks';
import { useRssFeed } from 'modules/rss';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'shared/Dropdown/Dropdown';
import { RssFeed } from './RssFeed/RssFeed';
import { CircularProgress } from '@material-ui/core';

export const RssContainer: React.VFC = () => {
  const { user, logoutUser } = useAuthManagement();
  const { t } = useTranslation();
  const { rssInfo, fetchRssInfo, isSuccess, rssFeedNames, isLoading } = useRssFeed();
  const { isModalShown } = useLayoutManagement();
  const [value, setValue] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    fetchRssInfo();
  }, [fetchRssInfo]);

  const handleSelectCategories = event => {
    var newUrl = rssInfo.find(item => item.name === event.target.value);
    setUrl(newUrl);
    setValue(event.target.value);
  };
  return (
    <>
      <div className={`fullApp ${isModalShown ? 'blur' : ''}`}>
        <Topbar logout={logoutUser} user={user}></Topbar>
        <div className="app_container">
          <div className="app_content">
            {!isLoading ? (
              <>
                {rssInfo && isSuccess && (
                  <div className="rss-wrapper">
                    <h1>{t('rss.title')}</h1>
                    <Dropdown
                      categories={rssFeedNames}
                      value={value ? value : rssFeedNames[0]}
                      id="feedlabel"
                      handleChange={handleSelectCategories}
                    />
                    <RssFeed url={value ? url.url : rssInfo[0].url} />
                  </div>
                )}
              </>
            ) : (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
