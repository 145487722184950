import { createAction } from '../../services/store';
import { ViewTypes } from './types';

export enum LayoutActionType {
  ShowModal = '[Layout]: Show modal',
  HideModal = '[Layout]: Hide modal',

  SetViewType = '[Layout]: Set view type',
}

export const LayoutAction = {
  showModal: () => createAction(LayoutActionType.ShowModal),
  hideModal: () => createAction(LayoutActionType.HideModal),
  setViewType: (viewType: ViewTypes) => createAction(LayoutActionType.SetViewType, { viewType }),
};
export type LayoutActionCreator = typeof LayoutAction;
