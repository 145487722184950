import type firebase from 'firebase';
import { Observable } from 'rxjs';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import type { StateObservable } from 'redux-observable';
import type { RootState } from 'services/store/root-reducer';
import type { AuthState } from './state';

export const isUserLoggedIn = (user: AuthState['user']['data']): user is firebase.User => {
  return Boolean(user);
};

export const filterNotLoggedInUser = (state$: StateObservable<RootState>) => {
  return <T>(source: Observable<T>) => {
    return source.pipe(
      withLatestFrom(state$),
      map(([, { auth }]) => auth.user.data),
      filter(isUserLoggedIn)
    );
  };
};
