import React from 'react';
import { Button, Input } from 'shared';
import { CardContainer } from 'components/Card';
import '../UserSettings.scss';
import { useTranslation } from 'react-i18next';
import { i18nClient } from 'services/translations';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useUserManagement } from 'modules/user';

const personalInfoFormSchema = Joi.object({
  name: Joi.string()
    .max(50)
    .messages({
      'string.base': i18nClient.t('general.failureMessage'),
      'string.max': i18nClient.t('customizeAccount.nameMaxFailureMessage'),
    }),
  age: Joi.number()
    .integer()
    .min(16)
    .max(120)
    .messages({
      'number.base': i18nClient.t('customizeAccount.ageFailureMessage'),
      'number.integer': i18nClient.t('customizeAccount.ageFailureMessage'),
      'number.max': i18nClient.t('customizeAccount.ageMaxFailureMessage'),
      'number.min': i18nClient.t('customizeAccount.ageMinFailureMessage'),
    }),
  location: Joi.string()
    .max(40)
    .messages({
      'string.base': i18nClient.t('general.failureMessage'),
      'string.max': i18nClient.t('customizeAccount.locationMaxFailureMessage'),
    }),
});

interface PersonalInfoData {
  name: string;
  age: number;
  location: string;
}

export const PersonalInformationContainer: React.FC = () => {
  const { errors, handleSubmit, control } = useForm<PersonalInfoData>({
    mode: 'onTouched',
    resolver: joiResolver(personalInfoFormSchema),
  });
  const { t } = useTranslation();
  const { saveUserPersonalInfo, userPersonalInfo } = useUserManagement();

  const { name, age, location } = userPersonalInfo;

  const isNameError = Boolean(errors.name);
  const isAgeError = Boolean(errors.age);
  const isLocationError = Boolean(errors.location);

  return (
    <CardContainer key={'personal-information'}>
      <span className="settings-header__title">{t('customizeAccount.personalInformationLabel')}</span>
      <form onSubmit={handleSubmit(saveUserPersonalInfo)} noValidate>
        <Controller
          control={control}
          name="name"
          defaultValue={name || ''}
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="text"
              label={t('customizeAccount.yourNameLabel')}
              value={field.value}
              error={isNameError && errors.name!.message}
            />
          )}
        />
        <Controller
          control={control}
          name="age"
          defaultValue={age || ''}
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="text"
              label={t('customizeAccount.ageLabel')}
              value={field.value}
              error={isAgeError && errors.age!.message}
            />
          )}
        />
        <Controller
          control={control}
          name="location"
          defaultValue={location || ''}
          render={field => (
            <Input
              name={field.name}
              onChange={field.onChange}
              type="text"
              label={t('customizeAccount.locationLabel')}
              value={field.value}
              error={isLocationError && errors.location!.message}
            />
          )}
        />
        <div className="settings-footer">
          <Button type="submit" style="dark">
            {t('general.saveChanges')}
          </Button>
        </div>
      </form>
    </CardContainer>
  );
};
