import { Box, FormControl, Select, MenuItem } from '@material-ui/core';
import './Dropdown.scss';
import React from 'react';

interface DropdownProps {
  id: string;
  categories: string[];
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({ id, categories, value, handleChange }) => {
  return (
    <Box className="dropdown-wrapper">
      <FormControl fullWidth className="dropdown-form">
        <Select
          labelId={id}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          disableUnderline
        >
          {categories.map(category => (
            <MenuItem key={category} value={category} className="dropdown-item">
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
