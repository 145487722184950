export const channels = [
  {
    id: 'all',
    name: 'All',
    icon: 'subject',
  },
  {
    id: 'news',
    name: 'News',
    icon: 'assignment',
  },
  {
    id: 'entertainment',
    name: 'Entertainment',
    icon: 'live_tv',
  },
  {
    id: 'sports',
    name: 'Sports',
    icon: 'directions_bike',
  },
  {
    id: 'finance',
    name: 'Finance',
    icon: 'account_balance',
  },
  {
    id: 'lifestyle',
    name: 'Lifestyle',
    icon: 'local_florist',
  },
  {
    id: 'health-fitness',
    name: 'Health & Fitness',
    icon: 'local_hospital',
  },
  {
    id: 'food-drink',
    name: 'Food & Drink',
    icon: 'restaurant',
  },
  {
    id: 'travel',
    name: 'Travel',
    icon: 'local_airport',
  },
  {
    id: 'automotive',
    name: 'Automotive',
    icon: 'time_to_leave',
  },
  {
    id: 'social-media',
    name: 'Social media',
    icon: 'group',
  },
  {
    id: 'world',
    name: 'World',
    icon: 'public',
  },
  {
    id: 'voted',
    name: 'Voted',
    icon: 'record_voice_over',
  },
  {
    id: 'stared',
    name: 'Stared',
    icon: 'star',
  },
];

export enum ClaimsCategory {
  All = 'All',
  Politics = 'Politics',
  Economy = 'Economy',
  Business = 'Business',
  Environment = 'Environment',
  Religion = 'Religion',
  Sports = 'Sports',
  Travel = 'Travel',
  Technology = 'Technology',
  Fashion = 'Fashion',
  Entertainment = 'Entertainment',
  News = 'News',
}
export const SortingTypes = [
  {
    key: 'recent',
    name: 'Recent',
    icon: 'access_time',
  },
  {
    key: 'popular',
    name: 'Popular',
    icon: 'whatshot',
  },
  {
    key: 'mostTruths',
    name: 'Truths',
    icon: 'check',
  },
  {
    key: 'mostBs',
    name: 'B.S.',
    icon: 'close',
  },
  // {
  //   id: 'activity',
  //   name: 'Activity',
  //   icon: 'notifications',
  // },
  // {
  //   id: 'popular',
  //   name: 'Popular',
  //   icon: 'trending_up',
  // },
  // {
  //   key: 'overturned',
  //   name: 'Overturned',
  //   icon: 'cached',
  // },
  {
    key: 'closeCall',
    name: 'Close calls',
    icon: 'compare_arrows',
  },
];
