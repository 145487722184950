import React, { useEffect } from 'react';
import { useAuthManagement } from 'modules/auth/hooks';
import { useUserManagement } from 'modules/user';

// rename fetchers to observers
export const LaunchObservers: React.FC = ({ children }) => {
  const { startAuthObserver, isLoggedIn } = useAuthManagement();
  const { fetchUserData, fetchUserCalls } = useUserManagement();

  useEffect(() => {
    startAuthObserver();
  }, [startAuthObserver]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserData();
      fetchUserCalls();
    }
  }, [isLoggedIn, fetchUserData, fetchUserCalls]);

  return <>{children}</>;
};
