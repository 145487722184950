import { useAuthManagement } from 'modules/auth';
import { useLayoutManagement } from 'modules/layout/hooks';
import { useTranslation } from 'react-i18next';
import { Topbar } from 'shared';
import './AboutContainer.scss';
import { AboutCard } from './AboutCard/AboutCard';

export const AboutContainer = () => {
  const { isModalShown } = useLayoutManagement();
  const { user, logoutUser } = useAuthManagement();
  const { t } = useTranslation();

  const aboutContent = [
    {
      title: t('about.h1'),
      paragraphs: [t('about.p1')],
    },
    {
      title: t('about.h2'),
      paragraphs: [t('about.p2')],
    },
    {
      title: t('about.h3'),
      paragraphs: [t('about.p3')],
    },
    {
      title: t('about.h4'),
      paragraphs: [t('about.p4One'), t('about.p4Two'), t('about.p4Three'), t('about.p4Four')],
    },
    {
      title: t('about.h5'),
      paragraphs: [t('about.p5')],
    },
  ];
  return (
    <div className={`fullApp ${isModalShown ? 'blur' : ''}`}>
      <Topbar logout={logoutUser} user={user}></Topbar>
      <div className="app_container">
        <div className="app_content">
          <div className="about-wrapper">
            <h1>{t('about.title')}</h1>
            {aboutContent.map(item => {
              return <AboutCard key={item.title} title={item.title} paragraphs={item.paragraphs} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
