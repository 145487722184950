import { createAction } from 'services/store/create-action';
import type firebase from 'firebase';
import { UserClaims, UserCredentials } from './types';

export enum AuthActionType {
  StartAuthObserver = '[Auth]: Start auth observer',
  StartAuthObserverFailure = '[Auth]: Start auth observer - failure',
  AuthObserverEmission = '[Auth]: Auth observer emission',

  LoginUser = '[Auth]: Login user',
  LoginUserSuccess = '[Auth]: Login user - success',
  LoginUserFailure = '[Auth]: Login user - failure',

  LogoutUser = '[Auth]: Logout user',
  LogoutUserSuccess = '[Auth]: Logout user - success',
  LogoutUserFailure = '[Auth]: Logout user - failure',

  RegisterUser = '[Auth]: Register user',
  RegisterUserSuccess = '[Auth]: Register user - success',
  RegisterUserFailure = '[Auth]: Register user - failure',

  ResetUserPassword = '[Auth]: Reset user password',
  ResetUserPasswordSuccess = '[Auth]: Reset user password - success',
  ResetUserPasswordFailure = '[Auth]: Reset user password - failure',

  ResetAuthErrors = '[Auth]: Reset authentication errors',

  ChangeUserPassword = '[Auth] Change user password',
  ChangeUserPasswordSuccess = '[Auth] Change user password - success',
  ChangeUserPasswordFailure = '[Auth] Change user password - failure',

  DeleteUser = '[Auth] Delete user',
  DeleteUserSuccess = '[Auth] Delete user - success',
  DeleteUserFailure = '[Auth] Delete user - failure',

  SetUserClaims = '[Auth]: Set user claims',

  verifyCaptcha = '[Auth]: Verify captcha',
  verifyCaptchaSuccess = '[Auth]: Verify captcha - success',
  verifyCaptchaFailure = '[Auth]: Verify captcha - failure',
  clearRecaptchaToken = '[Auth]: Verify captcha - clear token',
}

export const AuthAction = {
  startAuthObserver: () => createAction(AuthActionType.StartAuthObserver),
  startAuthObserverFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.StartAuthObserverFailure, { error }),
  authObserverEmission: (user: firebase.User | null) => createAction(AuthActionType.AuthObserverEmission, { user }),

  loginUser: (credentials: UserCredentials) => createAction(AuthActionType.LoginUser, { credentials }),
  loginUserSuccess: () => createAction(AuthActionType.LoginUserSuccess),
  loginUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.LoginUserFailure, { error }),

  logoutUser: () => createAction(AuthActionType.LogoutUser),
  logoutUserSuccess: () => createAction(AuthActionType.LogoutUserSuccess),
  logoutUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.LogoutUserFailure, { error }),

  registerUser: (credentials: UserCredentials) => createAction(AuthActionType.RegisterUser, { credentials }),
  registerUserSuccess: () => createAction(AuthActionType.RegisterUserSuccess),
  registerUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.RegisterUserFailure, { error }),

  resetUserPassword: (email: UserCredentials['email']) => createAction(AuthActionType.ResetUserPassword, { email }),
  resetUserPasswordSuccess: () => createAction(AuthActionType.ResetUserPasswordSuccess),
  resetUserPasswordFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.ResetUserPasswordFailure, { error }),

  resetAuthErrors: () => createAction(AuthActionType.ResetAuthErrors),

  changeUserPassword: (newPassword: UserCredentials['password']) =>
    createAction(AuthActionType.ChangeUserPassword, { newPassword }),
  changeUserPasswordSuccess: () => createAction(AuthActionType.ChangeUserPasswordSuccess),
  changeUserPasswordFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.ChangeUserPasswordFailure, { error }),

  deleteUser: () => createAction(AuthActionType.DeleteUser),
  deleteUserSuccess: () => createAction(AuthActionType.DeleteUserSuccess),
  deleteUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.DeleteUserFailure, { error }),

  setUserClaims: (userClaims: UserClaims) => createAction(AuthActionType.SetUserClaims, { userClaims }),

  verifyCaptcha: (token: string) => createAction(AuthActionType.verifyCaptcha, { token }),
  verifyCaptchaSuccess: (recaptchaResponse: firebase.functions.HttpsCallableResult) =>
    createAction(AuthActionType.verifyCaptchaSuccess, { recaptchaResponse }),
  verifyCaptchaFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.verifyCaptchaFailure, { error }),
  clearRecaptchaToken: () => createAction(AuthActionType.clearRecaptchaToken),
};

export type AuthActionCreator = typeof AuthAction;
