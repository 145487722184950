import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore } from 'services/store';
import App from 'features/App/App';
import './styles/main.scss';

const history = createBrowserHistory();
const store = createStore(history);

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </ReduxStoreProvider>,
  document.getElementById('root')
);

// registerServiceWorker();
