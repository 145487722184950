import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusFailure, isStatusPending, isStatusSuccessPending, isStatusSuccess } from 'modules/store/status';
import { RssAction } from './actions';
import { selectRssInfo } from './selectors';

export const useRssFeed = () => {
  const dispatch = useDispatch();

  const rssList = useSelector(selectRssInfo);
  const isLoading = isStatusPending(rssList.status);
  const isFailure = isStatusFailure(rssList.status);
  const isRefetching = isStatusSuccessPending(rssList.status);
  const isSuccess = isStatusSuccess(rssList.status);

  const fetchRssInfo = useCallback(() => {
    dispatch(RssAction.fetchRssInfo());
  }, [dispatch]);

  const getNames = () => {
    const urls = [];
    rssList.data.forEach(element => {
      urls.push(element.name);
    });
    return urls;
  };
  return {
    rssFeedNames: getNames(),
    rssInfo: rssList.data,

    isLoading,
    isFailure,
    isRefetching,
    isSuccess,

    fetchRssInfo,
  };
};
