export enum Status {
  DEFAULT = 'default',
  PENDING = 'pending',
  SUCCESS = 'success',
  SUCCESS_PENDING = 'success-pending',
  FAILURE = 'failure',
}

export const isStatusDefault = (status: Status | undefined): status is typeof Status.DEFAULT =>
  status === Status.DEFAULT;

export const isStatusPending = (status: Status | undefined): status is typeof Status.PENDING =>
  status === Status.PENDING;

export const isStatusSuccess = (status: Status | undefined): status is typeof Status.SUCCESS =>
  status === Status.SUCCESS;

export const isStatusSuccessPending = (status: Status | undefined): status is typeof Status.SUCCESS_PENDING =>
  status === Status.SUCCESS_PENDING;

export const isStatusFailure = (status: Status | undefined): status is typeof Status.FAILURE =>
  status === Status.FAILURE;
