import React from 'react';
import { MdAttachment } from 'react-icons/md';
import './Source.scss';

interface SourceProps {
  source: string;
}

export const Source: React.FC<SourceProps> = ({ source }) => {
  const { hostname } = new URL(source);

  return (
    <div className="source-wrapper">
      <a className="source__link" href={source} target="_blank" rel="noopener noreferrer">
        {hostname}
      </a>
      <MdAttachment className="source__icon" />
    </div>
  );
};
