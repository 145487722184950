import React, { useState } from 'react';
import { determineClaimVoteStatus, Claim } from 'modules/claims';
import { useAuthManagement } from 'modules/auth';
import { TileClaim, TileRate, TileResult, TileSource, TileVote, TileVotedPanel, TileAdminControls } from '.';
import { Call } from 'modules/user/types';
import './Tile.scss';
import { TileClaimedBy } from './TileClaimedBy/TileClaimedBy';
import { TileCategory } from './TileCategory/TileCategory';
import { TileControls } from './TileControls/TileControls';

interface TileProps {
  claim: Claim;
  userCall: Call;
  isSingleClaimView?: boolean;
}

export const Tile: React.FC<TileProps> = ({ claim, userCall, isSingleClaimView }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { isAdmin } = useAuthManagement();
  const { voteStatus, votePercentage } = determineClaimVoteStatus(claim);

  return (
    <div
      className={`tile ${isSingleClaimView ? 'tile--single-claim-view' : ''}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isAdmin ? (
        <TileAdminControls isVisible={isHovering} claim={claim} />
      ) : (
        <TileControls isVisible={isHovering} claim={claim} />
      )}
      <TileRate voteStatus={voteStatus} votePercentage={votePercentage} />
      <TileResult
        voteStatus={voteStatus}
        voteTotalCount={claim.totalCount}
        claimId={claim.id}
        claimCreatedAt={claim.createdAt}
      />
      <TileClaim text={claim.sentenceText} />
      <hr className="tile__break-line" />
      {userCall ? (
        <TileVotedPanel
          claim={claim}
          userCallId={userCall.id}
          userCallType={userCall.voteType}
          userCallCreatedAt={userCall.createdAt}
        />
      ) : (
        <TileVote claim={claim} />
      )}
      {claim.sourceUrl !== null && <TileSource source={claim.sourceUrl} />}
      {claim.claimedBy && <TileClaimedBy claimedBy={claim.claimedBy} />}
      {claim.category && <TileCategory category={claim.category} />}
    </div>
  );
};
