import React from 'react';
import type { Claim } from 'modules/claims';
import { Tile } from '../Tile/Tile';
import './TilesView.scss';
import { Call } from 'modules/user/types';

interface TilesViewProps {
  claims: Claim[];
  userCalls: Call[];
}

export const TilesView: React.FC<TilesViewProps> = ({ claims, userCalls }) => {
  return (
    <>
      {claims.map((claim, index) => {
        return <Tile key={claim.id} claim={claim} userCall={userCalls.find(call => call.claimId === claim.id)} />;
      })}
    </>
  );
};
