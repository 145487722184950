import React, { useState, useEffect } from 'react';
import { ActivityDashboard } from './ActivityDashboard/ActivityDashboard';
import { Topbar } from '../../shared';
import { useAuthManagement } from 'modules/auth';
import { useUserManagement } from 'modules/user';
import { ClaimsContainer } from 'features/Claims';
import { ClaimsTypes } from './types';
import { FeedHeader } from 'features/Claims/components/FeedHeader/FeedHeader';
import { ViewSwitch } from 'features/Claims/components/ViewSwitch/ViewSwitch';
import { CircularProgress } from '@material-ui/core';

export const ActivityContainer: React.FC = () => {
  const { user, logoutUser } = useAuthManagement();
  const {
    fetchUserVotedClaims,
    fetchUserStarredClaims,
    fetchUserAddedClaims,
    userVotedClaims,
    userStarredClaims,
    userAddedClaims,
    userCalls,
    isLoading,
    isRefetching,
  } = useUserManagement();

  const [claimsType, setClaimsType] = useState<ClaimsTypes>(ClaimsTypes.voted);

  useEffect(() => {
    fetchUserVotedClaims();
    fetchUserStarredClaims();
    fetchUserAddedClaims();
  }, [fetchUserAddedClaims, fetchUserStarredClaims, fetchUserVotedClaims]);

  const userStarredClaimsFiltered = userStarredClaims.filter(starredClaim => !!starredClaim.sourceUrl);
  const userVotedClaimsFiltered = userVotedClaims.filter(votedClaim => !!votedClaim.sourceUrl);
  const userAddedClaimsFiltered = userAddedClaims.filter(addedClaim => !!addedClaim.sourceUrl);
  return (
    <>
      <Topbar logout={logoutUser} user={user}></Topbar>
      <div className="app_container">
        <div className="app_content">
          <ActivityDashboard
            claimsType={claimsType}
            userStarredClaimsLength={userStarredClaimsFiltered.length}
            userVotedClaimsLength={userVotedClaims.length}
            userAddedClaimsLength={userAddedClaims.length}
            setClaimsType={setClaimsType}
          />
          <div className="claims">
            <FeedHeader>
              <ViewSwitch />
            </FeedHeader>
            {!isLoading || !isRefetching ? (
              <>
                {claimsType === ClaimsTypes.voted && (
                  <ClaimsContainer claims={userVotedClaimsFiltered} userCalls={userCalls} />
                )}
                {claimsType === ClaimsTypes.starred && (
                  <ClaimsContainer claims={userStarredClaimsFiltered} userCalls={userCalls} />
                )}
                {claimsType === ClaimsTypes.added && (
                  <ClaimsContainer claims={userAddedClaimsFiltered} userCalls={userCalls} />
                )}
              </>
            ) : (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
