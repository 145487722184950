import React from 'react';
import { MdClose } from 'react-icons/md';
import './BSIcon.scss';

function BSIcon() {
  return (
    <div className="icon__outer-circle--red">
      <div className="icon__inner-circle--red">
        <MdClose className="icon__close-icon" />
      </div>
    </div>
  );
}

export default BSIcon;
